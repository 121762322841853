import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface WebsiteResponseModel {
  message: string;
}

interface WebsiteRequestModel extends EndpointRequests {
  data: {
    website_ids: Array<number>;
    status: number;
  };
}

const updateWebsiteStatus = new Endpoint<
  WebsiteRequestModel,
  WebsiteResponseModel
>({
  url: '/api/me/websites/update_status',
  method: 'POST',
  headers: {},
});

export default updateWebsiteStatus;
