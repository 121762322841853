import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import { ConfirmationModal } from 'components/Modals/ConfirmationModal/ConfirmationModal';
import { v4 as uuidFunc } from 'uuid';

interface ConfirmationOptions {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

interface ConfirmationDialog extends ConfirmationOptions {
  id: string;
}

interface ConfirmationContextValue {
  confirm: (options: ConfirmationOptions) => void;
  destroyAll: () => void;
}

const ConfirmationContext = createContext<ConfirmationContextValue | null>(
  null,
);

export const ConfirmationProvider = ({ children }: { children: ReactNode }) => {
  const [confirmations, setConfirmations] = useState<ConfirmationDialog[]>([]);

  const destroy = useCallback((id: string) => {
    setConfirmations((prev) => prev.filter((conf) => conf.id !== id));
  }, []);

  const confirm = useCallback((options: ConfirmationOptions) => {
    const id = uuidFunc();

    const handleConfirm = () => {
      options.onConfirm();
      destroy(id);
    };

    const handleCancel = () => {
      options.onCancel?.();
      destroy(id);
    };

    setConfirmations((prev) => [
      ...prev,
      {
        ...options,
        id,
        onConfirm: handleConfirm,
        onCancel: handleCancel,
      },
    ]);

    return id;
  }, []);

  const destroyAll = useCallback(() => {
    setConfirmations([]);
  }, []);

  const contextValue = useMemo(
    () => ({ confirm, destroy, destroyAll }),
    [confirm, destroy, destroyAll],
  );

  return (
    <ConfirmationContext.Provider value={contextValue}>
      {children}
      {confirmations.map((conf) => (
        <ConfirmationModal
          key={conf.id}
          title={conf.title}
          message={conf.message}
          onConfirm={conf.onConfirm}
          onCancel={conf.onCancel}
        />
      ))}
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error(
      'useConfirmation must be used within a ConfirmationProvider',
    );
  }
  return context;
};
