import { Flex } from 'antd';
import { deleteFiltersAction } from 'layout/FiltersBar/actions';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import Typography from 'product-ui/src/components/atoms/Typography';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'product-ui/src/components/organism/Modal';

interface DeleteFilterModalProps {
  filterToDelete: SavedFilterModel | null;
  setFilterToDelete: Dispatch<SetStateAction<SavedFilterModel | null>>;
}
const modalStyles = {
  mask: {
    zIndex: 1100,
  },
};

const wrapProps = {
  style: {
    zIndex: 1150,
  },
};

export const DeleteFilterModal = ({
  filterToDelete,
  setFilterToDelete,
}: DeleteFilterModalProps) => {
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const current_screen = url.pathname.split('/')[2];

  const deleteFilterHandler = useCallback(() => {
    if (!filterToDelete) {
      return;
    }
    dispatch(deleteFiltersAction({ id: filterToDelete.id }, current_screen));
    setFilterToDelete(null);
  }, [filterToDelete]);

  return (
    <Modal
      open={!!filterToDelete}
      destroyOnClose
      wrapProps={wrapProps}
      styles={modalStyles}
      buttons={{
        cancel: {
          dataTestId: 'cancel',
          onClick: () => setFilterToDelete(null),
        },
        submit: {
          label: 'Delete',
          dataTestId: 'delete',
          variant: 'Red',
          onClick: deleteFilterHandler,
        },
      }}
    >
      <Flex align="stretch" justify="center">
        <Typography
          variant="large"
          fontWeight="bold"
          color="var(--neutral-grey-800)"
          textAlign="center"
        >
          Are you sure want to remove saved filter <br /> “
          {filterToDelete?.name}”?
        </Typography>
      </Flex>
    </Modal>
  );
};
