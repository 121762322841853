import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { DashboardCleanlinessScoreGraphResponseModel } from 'product-types/src/domain/dashboard/DashboardCleanlinessScoreGraphModel';

export interface DashboardCleanlinessScoreRequestParams
  extends CommonDashboardRequestParams {
  counterfeit_confirmed: boolean;
  start_date: string;
  end_date: string;
  offset: number;
  perpage: number;
  reverse: boolean;
  sort_by: string;
  timezone_offset: number;
  dates_aggregation: boolean;
  synchronous_computation: boolean;
  search_keyword: Array<string>;
  website_id: Array<string | number>;
  website_id_to_exclude: Array<string | number>;
  website_category_id: Array<string | number>;
  website_category_id_to_exclude: Array<string | number>;
}

export interface CleanlinessScoreRequest extends EndpointRequests {
  params: DashboardCleanlinessScoreRequestParams;
}

const getCleanlinessScoreGraph = new Endpoint<
  CleanlinessScoreRequest,
  DashboardCleanlinessScoreGraphResponseModel
>({
  url: '/api/me/stats/cleanliness/graph',
  method: 'GET',
  headers: {},
});

export default getCleanlinessScoreGraph;
