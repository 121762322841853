/*
 *
 * LoginPage constants
 *
 */

export const CHANGE_EMAIL = 'app/LoginPage/CHANGE_EMAIL';
export const CHANGE_PASSWORD = 'app/LoginPage/CHANGE_PASSWORD';
export const LOGIN_REQUEST = 'app/LoginPage/LOGIN_REQUEST';
export const UPDATE_ORGANISATIONS = 'app/LoginPage/UPDATE_ORGANISATIONS';
export const UPDATE_USER_INFO = 'app/LoginPage/UPDATE_USER_INFO';
export const PROCESS_LINK = 'app/LoginPage/PROCESS_LINK';
export const FETCH_ORGANISATIONS = 'app/LoginPage/FETCH_ORGANISATIONS';
export const LOGOUT = 'app/LoginPage/LOGOUT';
export const UPDATE_AUTHENTIFICATION = 'app/LoginPage/UPDATE_AUTHENTIFICATION';
export const LOGIN_ERROR = 'app/LoginPage/LOGIN_ERROR';
export const CLEAR_ERROR = 'app/LoginPage/CLEAR_ERROR';
export const SWITCH_ORGANISATION = 'app/LoginPage/SWITCH_ORGANISATION';
export const UPDATE_TARGET_ORGANISATION =
  'app/LoginPage/UPDATE_TARGET_ORGANISATION';
export const UPDATE_USER_MODEL = 'app/LoginPage/UPDATE_USER_MODEL';
export const FETCH_USER_MODEL = 'app/LoginPage/FETCH_USER_MODEL';
export const INITIALIZE_APP_STATE = 'app/LoginPage/INITIALIZE_APP_STATE';
export const UPDATE_LINK_HANDLED = 'app/LoginPage/UPDATE_LINK_HANDLED';
export const RESET_STATE = 'app/LoginPage/RESET_STATE';
export const UPDATE_SWITCHING_ORGANISATION =
  'app/LoginPage/UPDATE_SWITCHING_ORGANISATION';
