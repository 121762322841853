import {
  OrganisationModel,
  OrganisationRawModel,
} from '../organisation/Organisation';
import { RoleModel, RoleRaw } from '../role/Role';
import { LoginOrganisationRaw } from '../organisation/LoginOrganisation';

export interface UserWithPermissionsKpisRaw {
  id: number;
  email: string;
  name: string;
  is_internal: boolean;
  profile_picture_link: string;
  email_recap_frequency: string;
  email_when_new_counterfeit: boolean;
  organisation: OrganisationRawModel;
  role: RoleRaw;
  tutorial_mode: boolean;
  user_organisations: Array<LoginOrganisationRaw>;
  nb_posts_moderated: null | number;
  nb_images_moderated: null | number;
  nb_posts_checked: null | number;
  nb_posts_validated: null | number;
  qa_accuracy: null;
  verifier_accuracy: null;
  kpis?: {
    total_moderation: number | null;
    total_takedown: number | null;
    percentage_of_social_media_moderation: number | null;
    percentage_of_social_media_takedown: number | null;
    percentage_of_automatic_moderation: number | null;
  };
}

export class UserWithPermissionsKpis {
  _type = 'UserWithPermissionsKpis';

  id: number;

  email: string;

  name: string;

  isInternal: boolean;

  profilePictureLink: string;

  emailRecapFrequency: string;

  emailWhenNewCounterfeit: boolean;

  organisation: OrganisationModel;

  role: RoleModel;

  tutorialMode: boolean;

  kpis: {
    totalModeration: number | null;
    totalTakedown: number | null;
    percentageOfSocialMediaModeration: number | null;
    percentageOfSocialMediaTakedowns: number | null;
    percentageOfAutomaticModeration: number | null;
  };

  constructor(
    prop: Pick<
      UserWithPermissionsKpis,
      | 'email'
      | 'emailRecapFrequency'
      | 'emailWhenNewCounterfeit'
      | 'id'
      | 'name'
      | 'isInternal'
      | 'organisation'
      | 'profilePictureLink'
      | 'role'
      | 'tutorialMode'
      | 'kpis'
    >,
  ) {
    this.email = prop.email;
    this.emailRecapFrequency = prop.emailRecapFrequency;
    this.emailWhenNewCounterfeit = prop.emailWhenNewCounterfeit;
    this.id = prop.id;
    this.name = prop.name;
    this.isInternal = prop.isInternal;
    this.organisation = prop.organisation;
    this.profilePictureLink = prop.profilePictureLink;
    this.role = prop.role;
    this.tutorialMode = prop.tutorialMode;
    this.kpis = prop.kpis;
  }

  static createFromRawModel(prop: UserWithPermissionsKpisRaw) {
    return new UserWithPermissionsKpis({
      email: prop.email,
      emailRecapFrequency: prop.email_recap_frequency,
      emailWhenNewCounterfeit: prop.email_when_new_counterfeit,
      id: prop.id,
      name: prop.name,
      isInternal: prop.is_internal,
      organisation: OrganisationModel.createFromRawModel(prop.organisation),
      profilePictureLink: prop.profile_picture_link,
      role: RoleModel.createFromRawModel(prop.role),
      tutorialMode: prop.tutorial_mode,
      kpis: {
        totalModeration: prop.kpis?.total_moderation ?? null,
        totalTakedown: prop.kpis?.total_takedown ?? null,
        percentageOfSocialMediaModeration:
          prop.kpis?.percentage_of_social_media_moderation ?? null,
        percentageOfSocialMediaTakedowns:
          prop.kpis?.percentage_of_social_media_takedown ?? null,
        percentageOfAutomaticModeration:
          prop.kpis?.percentage_of_automatic_moderation ?? null,
      },
    });
  }
}
