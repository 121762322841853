import { AppState } from 'store/storeAccess';
import React, { CSSProperties, useMemo } from 'react';
import { useSelector } from 'react-redux';

const stylesDefault = {
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundColor: 'white',
  width: '100px',
  height: '40px',
  display: 'flex',
  marginLeft: '0.5rem',
};

export const Logo = ({ style }: { style: CSSProperties }) => {
  const picture = useSelector(
    (state: AppState) =>
      state.loginPage?.currentUser?.data?.organisation?.logoUrl,
  );
  const styles = useMemo(
    () => ({
      ...stylesDefault,
      backgroundImage: `url(${picture})`,
      ...style,
    }),
    [picture],
  );
  return <div data-testid="logo" style={styles} />;
};
