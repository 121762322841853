import { Flex, notification } from 'antd';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import Typography from 'product-ui/src/components/atoms/Typography';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import ProductMonitor from 'types/network/Http/productMonitor';
import { loadFiltersAction } from 'layout/FiltersBar/actions';
import { useDispatch } from 'react-redux';
import { Modal } from 'product-ui/src/components/organism/Modal';
import { DeleteFilterModal } from './DeleteFilterModal';

interface EditFilterModalProps {
  filterToEdit: SavedFilterModel | null;
  setFilterToEdit: Dispatch<SetStateAction<SavedFilterModel | null>>;
}

const modalStyles = {
  mask: {
    zIndex: 1100,
  },
};

const wrapProps = {
  style: {
    zIndex: 1150,
  },
};

export const EditFilterModal = ({
  filterToEdit,
  setFilterToEdit,
}: EditFilterModalProps) => {
  const [saveFiltername, setSaveFiltername] = useState('');
  const [updating, setUpdating] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<SavedFilterModel | null>(
    null,
  );
  const [closeAfterDelete, setCloseAfterDelete] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (filterToEdit) {
      setSaveFiltername(filterToEdit.name);
      setIsDefault(filterToEdit.isDefault);
    }
  }, [filterToEdit]);

  const saveFilterHandler = useCallback(async () => {
    if (!filterToEdit) {
      return;
    }
    setUpdating(true);
    try {
      await ProductMonitor.endpoints.me.updateFilter.call({
        data: {
          id: filterToEdit?.id,
          name: saveFiltername,
          is_default: isDefault,
        },
      });
      setFilterToEdit(null);
      dispatch(loadFiltersAction());
      notification.success({
        message: 'Filter updated successfully',
        placement: 'bottomRight',
        duration: 3,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error loading non tracked websites', e);
    } finally {
      setUpdating(false);
    }
  }, [updating, filterToEdit, saveFiltername, isDefault]);

  const removeFilterHandler = useCallback(() => {
    setFilterToDelete(filterToEdit);
  }, [filterToEdit]);

  const sendDisabled = useMemo(
    () => !saveFiltername || updating,
    [updating, saveFiltername],
  );

  return (
    <>
      <Modal
        title="Edit Saved Filter"
        open={!!filterToEdit && !filterToDelete && !closeAfterDelete}
        destroyOnClose
        size="medium"
        wrapProps={wrapProps}
        styles={modalStyles}
        buttons={{
          submit: {
            label: 'Save Filter',
            dataTestId: 'save',
            disabled: sendDisabled,
            onClick: saveFilterHandler,
          },
          cancel: {
            dataTestId: 'cancel',
            onClick: () => setFilterToEdit(null),
          },
        }}
      >
        <Flex vertical align="stretch" gap="8px">
          <Flex vertical align="stretch" gap="0.25rem">
            <label htmlFor="filterName">
              <Typography
                variant="xsmall"
                fontWeight="bold"
                letterSpacing="0.24px"
                color="var(--neutral-grey-70)"
                textTransform="uppercase"
              >
                Filter name
              </Typography>
            </label>
            <Input
              id="filterName"
              placeholder="Please enter filter configuration name here"
              value={saveFiltername}
              onChange={(e) => {
                setSaveFiltername(e.target.value);
              }}
            />
          </Flex>
          <Checkbox
            label="Set as default"
            checked={isDefault}
            onChange={setIsDefault}
          />
          <Flex
            gap="8px"
            align="center"
            style={{ cursor: 'pointer' }}
            onClick={removeFilterHandler}
          >
            <NaveeIcon.TrashIcon
              fill="var(--salmon-salmon-7)"
              height={20}
              width={20}
            />
            <Typography
              color="var(--salmon-salmon-7)"
              textTransform="uppercase"
              variant="xsmall"
              fontWeight="bold"
              style={{
                letterSpacing: '2px',
                lineHeight: '24px',
              }}
            >
              remove Filter
            </Typography>
          </Flex>
        </Flex>
      </Modal>
      <DeleteFilterModal
        filterToDelete={filterToDelete}
        setFilterToDelete={(filter) => {
          setFilterToDelete(filter);
          setCloseAfterDelete(true);
          setFilterToEdit(null);
        }}
      />
    </>
  );
};
