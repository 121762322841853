export enum UserFilterRoleEnum {
  commenter = 'Commenter',
  moderator = 'Moderator',
  qa = 'QA',
  validator = 'Validator',
  uploader = 'Uploader',
}

export interface UserFilterRoleElement {
  label: string;
  value: UserFilterRoleEnum;
}

export const UserFilterRoleDictionary = {
  commenter: {
    label: 'Commenter',
    value: UserFilterRoleEnum.commenter,
  },
  moderator: {
    label: 'Moderator',
    value: UserFilterRoleEnum.moderator,
  },
  qa: {
    label: 'QA',
    value: UserFilterRoleEnum.qa,
  },
  validator: {
    label: 'Validator',
    value: UserFilterRoleEnum.validator,
  },
  uploader: {
    label: 'Uploader',
    value: UserFilterRoleEnum.uploader,
  },
};

export const UserFilterRoleOptions: UserFilterRoleElement[] = Object.values(
  UserFilterRoleDictionary,
);

export const GetUserFilterOptionByValue = (value) =>
  UserFilterRoleOptions.find(
    (uploadStatusOption) => uploadStatusOption.value === value,
  );
export const GetOptionByUserRole = (value) =>
  UserFilterRoleOptions.find(
    (uploadStatusOption) => uploadStatusOption.value === value,
  );
