import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { Filter, FilterNamespace } from '../index';
import { DisplayingFilterValue } from '../AtomicFilters/DisplayingFilterValue';
import {
  FiltersContainer,
  ReadFromQueryParams,
} from '../MoleculesFilter/MolecileFilter';
import {
  DateFilter,
  DateFilterQueryValue,
} from '../AtomicFiltersImplementation/Date/DateFilter';
import { EstimatedGeoFilterQueryValue } from '../AtomicFiltersImplementation/EstimatedGeo/EstimatedGeoFilter';
import { ProductFilterQueryValue } from '../AtomicFiltersImplementation/Product/ProductFilter';
import { WebsiteFilterQueryValue } from '../AtomicFiltersImplementation/Website/WebsiteFilter';
import { DashboardSyncComputationQueryValue } from '../AtomicFiltersImplementation/DashboardSyncComputation/DashboardSyncComputation';

export interface DashboardFiltersQueryValue
  extends QueryValue,
    DateFilterQueryValue,
    EstimatedGeoFilterQueryValue,
    ProductFilterQueryValue,
    WebsiteFilterQueryValue,
    DashboardSyncComputationQueryValue {}

export interface DashboardFiltersModelParams {
  currentFilters?: FilterNamespace.Filter[];
  uuid?: string;
}
export class DashboardFiltersModel implements FiltersContainer {
  uuid: string;

  currentFilters: FilterNamespace.Filter[];

  constructor(params?: DashboardFiltersModelParams) {
    this.uuid = params?.uuid ?? uuid();
    this.currentFilters = params?.currentFilters || this.createEmptyFilters();
  }

  get queryParams(): DashboardFiltersQueryValue {
    return {
      ...(this.currentFilters[0] as DateFilter).queryFilterValue,
      ...(this.currentFilters[1] as Filter.EstimatedGeo).queryFilterValue,
      ...(this.currentFilters[2] as Filter.Product).queryFilterValue,
      ...(this.currentFilters[3] as Filter.Website).queryFilterValue,
      ...(this.currentFilters[4] as Filter.DashboardSyncComputation)
        .queryFilterValue,
      ...(this.currentFilters[5] as Filter.KeywordsCleanliness)
        .queryFilterValue,
    };
  }

  get queryParamAsString(): string {
    return JSON.stringify(this.queryParams);
  }

  queryParamsExcept(filterToExclude: FilterNamespace.Filter): string {
    return JSON.stringify(
      this.currentFilters
        .filter((filter) => filter.uuid !== filterToExclude.uuid)
        .reduce(
          (acc, item: FilterNamespace.Filter) => ({
            ...acc,
            ...item.queryFilterValue,
          }),
          {} as Partial<QueryValue>,
        ),
    );
  }

  get displayingFilters(): DisplayingFilterValue[] {
    return this.currentFilters
      .map((filter) => filter.displayingFilterValue)
      .flat(1);
  }

  removeFilterValue(filterToDelete: DisplayingFilterValue) {
    return new DashboardFiltersModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (
            filter.uuid === filterToDelete.uuid &&
            filter.removeFilterValue !== undefined
          ) {
            filter.removeFilterValue(filterToDelete as DisplayingFilterValue);
          }
          return filter;
        },
      ),
    });
  }

  updateFilterValue(filterToUpdate: FilterNamespace.Filter) {
    return new DashboardFiltersModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToUpdate.uuid) {
            return filterToUpdate;
          }
          return filter;
        },
      ),
    });
  }

  createEmptyFilters() {
    return [
      new Filter.Date(),
      new Filter.EstimatedGeo(),
      new Filter.Product(),
      new Filter.Website(),
      new Filter.DashboardSyncComputation(),
      new Filter.KeywordsCleanliness(),
    ];
  }

  createFiltersFromQuery(props: ReadFromQueryParams) {
    return [
      Filter.Date.readFilterFromQuery(),
      Filter.EstimatedGeo.readFilterFromQuery(props),
      Filter.Product.readFilterFromQuery(props),
      Filter.Website.readFilterFromQuery(props),
      Filter.DashboardSyncComputation.readFilterFromQuery(),
      Filter.KeywordsCleanliness.readFilterFromQuery(),
    ];
  }

  get productFilter() {
    return this.currentFilters[2];
  }

  get geoFilter() {
    return this.currentFilters[1];
  }

  get websiteFilter() {
    return this.currentFilters[3];
  }

  get dateFilter() {
    return this.currentFilters[0];
  }

  get syncComputation() {
    return this.currentFilters[4];
  }

  get searchFilter() {
    throw new Error('Filter does not exist');
    return null as unknown as FilterNamespace.Filter;
  }

  resetFilters() {
    this.currentFilters = this.createEmptyFilters();
    return this;
  }

  readFilterFromQuery(props: ReadFromQueryParams) {
    return new DashboardFiltersModel({
      uuid: this.uuid,
      currentFilters: this.createFiltersFromQuery(props),
    });
  }
}
