import React from 'react';
import { Flex, Modal as AntdModal, ModalProps, Tooltip } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';
import { Badge } from 'product-ui/src/components/atoms/Badge/Badge';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Button, ButtonProps } from 'product-ui/src/components/atoms/Button';
import styled from 'styled-components';

interface ModalHeaderProps {
  title?: string;
  badge?: Props['badge'];
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, badge, onClose }) => (
  <Flex
    align="center"
    justify="flex-end"
    gap="1rem"
    style={{
      padding: title ? '1rem 1.5rem' : '1rem 1.5rem 0 1.5rem',
      borderBottom: title ? '0.5px solid var(--primary-border)' : 'none',
      height: title ? '4rem' : '2.5rem',
    }}
  >
    {title && (
      <Flex flex={1} align="center" gap="0.5rem">
        <Typography
          variant="h7"
          fontWeight="bold"
          color="var(--neutral-grey-800)"
        >
          {title}
        </Typography>
        {badge && (
          <Badge
            loading={badge.loading}
            color="mint-green"
            total={badge.content}
          />
        )}
      </Flex>
    )}
    <Button
      type="IconOnly"
      buttonType="button"
      variant="Text"
      onClick={onClose}
      style={{ padding: 0 }}
      icon={
        <NaveeIcon.Cross
          width={13}
          height={13}
          stroke="var(--neutral-grey-500)"
        />
      }
    />
  </Flex>
);

type FooterProps = Pick<Props, 'footerLeftSlot'> & {
  isSmall: boolean;
  buttons: Props['buttons'];
};

const ModalFooter: React.FC<FooterProps> = ({
  footerLeftSlot,
  isSmall,
  buttons,
}) => (
  <Flex
    align="center"
    justify={isSmall ? 'center' : 'flex-end'}
    gap="1rem"
    style={{
      height: '80px',
      padding: '1.5rem',
      borderTop: '0.5px solid var(--primary-border)',
    }}
  >
    {!isSmall && footerLeftSlot && (
      <div style={{ flex: '1 0 0' }}>{footerLeftSlot}</div>
    )}
    {(buttons.cancel.show ?? true) && (
      <Button
        dataTestId={buttons.cancel.dataTestId}
        key="cancel"
        size="Small"
        variant="Outline"
        buttonType={buttons.cancel.buttonType ?? 'button'}
        disabled={buttons.cancel.disabled}
        style={isSmall ? { flex: 1 } : {}}
        label={buttons.cancel.label ?? 'CANCEL'}
        onClick={buttons.cancel.onClick}
      />
    )}
    {(buttons.submit.show ?? true) && (
      <Tooltip title={buttons.submit.tooltip}>
        <Button
          dataTestId={buttons.submit.dataTestId}
          key="submit"
          size="Small"
          buttonType={buttons.submit.buttonType ?? 'submit'}
          variant={buttons.submit.variant ?? 'Secondary'}
          disabled={buttons.submit.disabled}
          style={isSmall ? { flex: 1 } : {}}
          label={buttons.submit.label ?? 'Submit'}
          onClick={buttons.submit.onClick}
        />
      </Tooltip>
    )}
  </Flex>
);

type FooterButtons = {
  submit: ButtonProps & { tooltip?: string; show?: boolean };
  cancel: ButtonProps & { show?: boolean };
};

interface Props {
  open: boolean;
  className?: string;
  centered?: boolean;
  dataTestId?: string;
  wrapProps?: ModalProps['wrapProps'];
  styles?: ModalProps['styles'];
  destroyOnClose?: boolean;
  size?: 'small' | 'medium' | 'large' | 'xLarge';
  title?: string;
  badge?: { loading?: boolean; content: number };
  footerLeftSlot?: React.ReactNode;
  buttons: FooterButtons;
  footer?: (originNode: React.ReactNode) => React.ReactNode;
  modalRender?: (originNode: React.ReactNode) => React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
}

const sizeMap = {
  small: '400px',
  medium: '600px',
  large: '960px',
  xLarge: '1140px',
};

const ModalContent = styled.div`
  .ant-modal-header {
    margin-bottom: 0;
  }
  .ant-modal-footer {
    margin-top: 0;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  & > div {
    padding: 0 !important;
  }
`;

export const Modal: React.FC<Props> = ({
  open,
  size,
  title,
  badge,
  centered,
  wrapProps,
  styles,
  className,
  dataTestId,
  destroyOnClose,
  buttons,
  footer,
  footerLeftSlot,
  modalRender,
  children,
  onClose,
}) => {
  const isSmall = (size ?? 'small') === 'small';

  const footerContent = (
    <ModalFooter
      isSmall={isSmall}
      footerLeftSlot={footerLeftSlot}
      buttons={buttons}
    />
  );
  const modalContent = (modal: React.ReactNode) => (
    <ModalContent>{modal}</ModalContent>
  );
  return (
    <AntdModal
      data-testid={dataTestId}
      className={className}
      wrapProps={wrapProps}
      title={
        <ModalHeader
          title={title}
          badge={badge}
          onClose={onClose ?? buttons.cancel.onClick}
        />
      }
      closable={false}
      centered={centered ?? true}
      destroyOnClose={destroyOnClose ?? false}
      open={open}
      footer={footer ? footer(footerContent) : footerContent}
      width={sizeMap[size ?? 'small']}
      modalRender={(modal) =>
        modalRender ? modalRender(modalContent(modal)) : modalContent(modal)
      }
      styles={{
        body: {
          display: 'flex',
          padding: '1.5rem',
          flexDirection: 'column',
          justifyContent: isSmall ? 'center' : 'flex-start',
          alignItems: isSmall ? 'center' : 'flex-start',
        },
        ...styles,
      }}
    >
      <div style={{ width: '100%' }}>{children}</div>
    </AntdModal>
  );
};
