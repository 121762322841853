import { PostFeedDataModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { UPDATE_RELATED_POSTS } from 'containers/ModerationPage/constants';
import { QueryValue } from 'product-types/src/network/Query/Query';
import {
  LOAD_DUPLICATED_GROUP_DATA,
  LOAD_HISTORICAL_ANALYSIS_DATA,
  LOAD_POSTS_COUNT,
  LOAD_POSTS_DATA,
  LOAD_RELATED_POSTS_DATA,
  RECRAWL_POST,
  RESET_STATE,
  UPDATE_POSTS_COUNT,
  UPDATE_POST_MODERATION_STATE,
  UPDATE_TAKEN_DOWN_STATUS,
} from './constants';

export const loadPostsDataAction = ({
  pageState,
  successAction,
  abortController,
}: {
  pageState: QueryValue;
  successAction: string;
  abortController?: AbortController;
}) => ({
  type: LOAD_POSTS_DATA,
  abortController,
  successAction,
  pageState,
});

export const loadPostsCountAction = ({
  pageState,
  abortController,
}: {
  pageState: QueryValue;
  abortController?: AbortController;
}) => ({
  type: LOAD_POSTS_COUNT,
  abortController,
  pageState,
});

export const loadRelatedPostsDataAction = (pageState: QueryValue) => ({
  pageState,
  type: LOAD_RELATED_POSTS_DATA,
});

export const loadHistorycalDataAnalysis = (pageState: QueryValue) => ({
  pageState,
  type: LOAD_HISTORICAL_ANALYSIS_DATA,
});

export const updateRelatedPosts = (
  postsData: FetchableData<PostFeedDataModel>,
) => ({
  postsData,
  type: UPDATE_RELATED_POSTS,
});

export const updatePostsCount = (postsCount: FetchableData<number>) => ({
  postsCount,
  type: UPDATE_POSTS_COUNT,
});

export const updatePostModerationState = (state: FetchableDataState) => ({
  state,
  type: UPDATE_POST_MODERATION_STATE,
});

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export interface RecrawlPostsProps {
  posts: Array<{
    url: string;
    label: string;
  }>;
  success_action: () => any;
}
export function recrawlPosts(params: RecrawlPostsProps) {
  return {
    type: RECRAWL_POST,
    ...params,
  };
}

export interface UpdateTakedownStatusProps {
  post_ids: Array<number>;
  taken_down: boolean;
  successAction: () => any;
  failureAction?: () => any;
}

export function updateTakedownStatus(props: UpdateTakedownStatusProps) {
  return {
    type: UPDATE_TAKEN_DOWN_STATUS,
    ...props,
  };
}

export const loadPostsDataSuccessAction = (
  type: string,
  postsData: FetchableData<PostFeedDataModel>,
) => ({
  type,
  postsData,
});

export const loadDuplicatedGroupsDataAction = (
  pageState,
  setLoading,
  successAction,
) => ({
  type: LOAD_DUPLICATED_GROUP_DATA,
  setLoading,
  successAction,
  ...pageState,
});
