/* eslint-disable no-restricted-syntax */
import { TreeDataNode } from 'antd';
import { ProductCategory, ProductCategoryRaw } from './ProductCategory';

export type FlatSelect = {
  id: number | string;
  name: string;
  displayName: string | null;
  path: string;
  collapsingIds?: Array<number | string>;
};

type TreeCache = {
  treeData: Array<TreeDataNode> | null;
  others: Array<ProductCategory>;
  collapsed: Array<{
    parent: {
      displayName?: string;
      children: Array<ProductCategory>;
    };
    ids: Array<number | string>;
    key: string;
    title: string;
  }>;
  flatSelect: any;
};

type ChildrenArray = Array<{ id: number | string; children: ChildrenArray }>;

export class ProductCategoryTree {
  // roots
  children: Array<ProductCategory>;

  cache: TreeCache = {
    treeData: null,
    others: [],
    // cached while building the tree select using toTreeData
    collapsed: [],
    flatSelect: null,
  };

  constructor(prop: Pick<ProductCategoryTree, 'children'>) {
    this.children = prop.children;
    if (this.children.length) {
      this.calcCache();
    }
  }

  get configurableChildren() {
    return this.children.filter((c) => c.isConfigurable);
  }

  find(id: number | string) {
    let found = this.children.find((category) => category.id === id);
    if (found) {
      return found;
    }

    const find = (category: ProductCategory) => {
      let f = category.childrenMap.get(id as any) ?? null;
      if (f) {
        return f;
      }
      for (const c of category.children) {
        f = find(c);
        if (f) {
          return f;
        }
      }
      return null;
    };

    for (const category of this.children) {
      found = find(category);
      if (found) {
        return found;
      }
    }
    return null;
  }

  flatten(): Array<ProductCategory> {
    const flatten = (category: { children: Array<ProductCategory> }) =>
      category.children.reduce(
        (acc, c) => [...acc, c, ...flatten(c)],
        [] as Array<ProductCategory>,
      );

    return flatten(this);
  }

  selectedKeys() {
    let selectedKeys: Array<number | string> = this.children
      .filter(
        (category) =>
          category.isSelected &&
          !category.indeterminate &&
          category.isConfigurable,
      )
      .map((category) => category.id);
    this.children.forEach((category) => {
      selectedKeys = [...selectedKeys, ...category.selectedKeys()];
    });
    return selectedKeys;
  }

  toRawModel(): Array<ProductCategoryRaw> {
    return this.children.map((child) => child.toRawModel());
  }

  calcCache() {
    /* eslint-disable no-unused-expressions */
    this.toTreeData;
  }

  get otherCategory() {
    return this.children.find((c) => c.isOther);
  }

  get toTreeData() {
    if (this.cache.treeData) {
      return this.cache.treeData;
    }

    const pluckLeaves: (category: ProductCategory) => Array<ProductCategory> = (
      category: ProductCategory,
    ) => {
      if (category.isLeaf) {
        return [category];
      }
      return category.children.flatMap(pluckLeaves);
    };

    const toTree = (category: {
      name?: string;
      children: Array<ProductCategory>;
    }) => {
      const selectedChildren = category.children.filter(
        (c) => c.isSelected && !c.isOther,
      );
      const unselectedChildren = category.children.filter((c) => !c.isSelected);
      const hasOtherCategory = category.children.some((c) => c.isOther);

      const selectedNodes = selectedChildren.map((c) => ({
        key: c.id,
        id: c.id,
        title: c.displayName ?? '',
        children: toTree(c),
      }));

      if (unselectedChildren.length > 0 || hasOtherCategory) {
        const collapsingLeaves = unselectedChildren
          .flatMap(pluckLeaves)
          .concat(category.children.filter((c) => c.isOther));

        const leafIds = collapsingLeaves.map((l) => l.id);
        const collapsed = {
          id: 'others',
          key: `${category.name!}-${leafIds.join(',')}-others`,
          title: 'Others',
        };
        selectedNodes.push(collapsed);
        this.cache.collapsed.push({
          ...collapsed,
          parent: category,
          ids: leafIds,
        });
        this.cache.others.push(...collapsingLeaves);
      }

      // TT-15827: Move "No Category" to the end of the list
      const noCategoryIndex = selectedNodes.findIndex(
        (o) => o.title === 'No Category',
      );
      const otherIndex = selectedNodes.findIndex((o) => o.title === 'Others');
      if (noCategoryIndex !== -1 && otherIndex !== -1) {
        // Remove "no category" from its current position
        const [noCategory] = selectedNodes.splice(noCategoryIndex, 1);

        // Insert "no category" right after "other"
        selectedNodes.splice(otherIndex + 1, 0, noCategory);
      }
      return selectedNodes;
    };

    this.cache.treeData = toTree(this);
    return this.cache.treeData!;
  }

  get toFlatSelect(): Array<FlatSelect> {
    if (this.cache.flatSelect) {
      return this.cache.flatSelect;
    }

    const toFlatSelect = (category: ProductCategory): FlatSelect => {
      if (
        category.isLeaf &&
        category.isSelected &&
        !category.isOther &&
        category.isConfigurable
      ) {
        return [
          {
            id: category.id,
            name: category.name,
            displayName: category.displayName,
            path: ProductCategory.path(category)
              .map((item) => item.displayName)
              .join('/'),
          },
        ] as any;
      }

      return category.children.flatMap(toFlatSelect) as any;
    };

    this.cache.flatSelect = this.children.flatMap(toFlatSelect).concat([
      {
        id: this.otherCategory?.id ?? 0,
        name: 'others',
        displayName: 'Others',
        path: 'Others',
        collapsingIds: this.cache.others.map((c) => c.id),
      },
    ]);

    return this.cache.flatSelect;
  }

  get collapsedLeafIds() {
    return (this.cache?.collapsed ?? []).flatMap((c) => c.ids);
  }

  get length() {
    const count = (category: { children: ChildrenArray }) =>
      category.children.reduce(
        (acc, c) => acc + count(c),
        category.children.filter((c) => c.id !== 'no_category').length,
      );
    return count(this);
  }

  getTotalDisplayCount(): number {
    const countLeafCategories = (node: ProductCategory): number => {
      if (node.id === 'no_category') {
        return 0;
      }

      if (!node.children || node.children.length === 0) {
        return 1;
      }

      return node.children.reduce(
        (sum, child) => sum + countLeafCategories(child),
        0,
      );
    };

    return this.children.reduce(
      (total, category) => total + countLeafCategories(category),
      0,
    );
  }

  getSelectedDisplayCount(): number {
    const countSelectedCategories = (node: ProductCategory): number => {
      if (node.id === 'no_category') {
        return 0;
      }

      if (node.isSelected) {
        if (!node.children || node.children.length === 0) {
          return 1;
        }
        return node.children.reduce(
          (sum, child) =>
            sum + (child.children?.length ? child.children.length : 1),
          0,
        );
      }

      let count = 0;
      if (node.children) {
        node.children.forEach((child) => {
          count += countSelectedCategories(child);
        });
      }
      return count;
    };

    return this.children.reduce(
      (total, category) => total + countSelectedCategories(category),
      0,
    );
  }

  static createFromRawModel(prop: Array<ProductCategoryRaw>) {
    return new ProductCategoryTree({
      children: prop
        .map((category) => ProductCategory.createFromRawModel(category))
        .sort(ProductCategory.sortingComparator),
    });
  }
}
