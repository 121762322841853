/* eslint-disable no-restricted-syntax, no-continue */
import { call, put, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import React from 'react';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import {
  ADD_MEMBERS_TO_TAG,
  ADD_TAGS_TO_LIST,
  REMOVE_OBJECTS_FROM_TAG,
} from './constants';
import { loadFiltersBarTagsAction } from '../FiltersBar/actions';
import { AddTagToTaggableList, setFeedFooterFieldAction } from './actions';
import ProductMonitor from '../../types/network/Http/productMonitor';

function* addTagsToTaggableList(action: AddTagToTaggableList) {
  try {
    const newTags: Array<TagModel> = [];
    for (const tag of action.tags) {
      if (tag.__isNew__) {
        const tagResponse = yield call(createTag, {
          tag_type: action.tagType === 'website' ? 'account' : action.tagType,
          name: tag.label,
        });
        if (
          tagResponse.message === 'User In tutorial Mode - action not saved'
        ) {
          notification.info({
            message: tagResponse.message,
            placement: 'bottomRight',
            duration: 5,
          });
          continue;
        }
        newTags.push(
          new TagModel({
            id: tagResponse.object.id,
            name: tag.label,
            tagType: action.tagType,
          }),
        );
      } else {
        newTags.push(tag);
      }
    }
    let lastRes = null as any;
    for (const tag of newTags) {
      lastRes = yield call(
        ProductMonitor.endpoints.me.organisation.updateTagMembers.call.bind(
          ProductMonitor.endpoints.me.organisation.updateTagMembers,
        ),
        {
          urlParams: { id: tag.id },
          data: { member_ids: action.taggableList },
        },
      );
    }
    notification.info({
      message: lastRes?.message ?? 'All elements were successfully tagged',
      placement: 'bottomRight',
      icon: (
        <NaveeIcon.CheckGreen
          style={{
            color: 'var(--custom-green)',
            fontSize: 16,
            marginBottom: 8,
          }}
        />
      ),
      duration: 5,
    });

    if (action.tags.some((tag) => tag.__isNew__)) {
      yield put(loadFiltersBarTagsAction(action.tagType));
    }

    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (err: any) {
    console.error(err);
    action.onError?.();
    if (err.status !== 403) {
      notification.error({
        message: CustomErrorFactory.create(err).message,
        placement: 'bottomRight',
        duration: 5,
      });
    }
  } finally {
    yield put(setFeedFooterFieldAction('tags_loading', false));
  }
}

function* updateMembersForTags(action) {
  const {
    member_ids,
    tag: { value, __isNew__, label },
    tag_type,
    success_action,
    errorAction,
    shouldRefreshTags = true,
  } = action;

  try {
    let tag_id = value;
    if (__isNew__) {
      const tag = yield call(createTag, {
        tag_type: tag_type === 'website' ? 'account' : tag_type,
        name: label,
      });
      if (tag.message === 'User In tutorial Mode - action not saved') {
        notification.info({
          message: tag.message,
          placement: 'bottomRight',
          duration: 5,
        });
        return;
      }
      tag_id = tag.object.id;
    }
    const res = yield call(
      ProductMonitor.endpoints.me.organisation.updateTagMembers.call.bind(
        ProductMonitor.endpoints.me.organisation.updateTagMembers,
      ),
      {
        urlParams: { id: tag_id },
        data: { member_ids },
        suppressToastrOnError: true,
      },
    );

    notification.info({
      message: res.message,
      placement: 'bottomRight',
      icon: (
        <NaveeIcon.CheckGreen
          style={{
            color: 'var(--custom-green)',
            fontSize: 16,
            marginBottom: 8,
          }}
        />
      ),
      duration: 5,
    });

    if (shouldRefreshTags) {
      yield put(loadFiltersBarTagsAction(tag_type));
    }

    success_action?.();
  } catch (err: any) {
    notification.error({
      message: err.response?.data?.['error message'] || err.message,
      placement: 'bottomRight',
      duration: 5,
    });
    errorAction?.();
  } finally {
    yield put(setFeedFooterFieldAction('tags_loading', false));
  }
}

function* createTag(action) {
  const { tag_type, name } = action;

  return yield call(
    ProductMonitor.endpoints.me.organisation.createTag.call.bind(
      ProductMonitor.endpoints.me.organisation.createTag,
    ),
    { data: { tag_type, name, id: 0 } },
  );
}

function* deleteTag(action) {
  const { tag_id, member_ids, success_action, onError = undefined } = action;

  try {
    yield call(
      ProductMonitor.endpoints.me.organisation.deleteTagMembers.call.bind(
        ProductMonitor.endpoints.me.organisation.deleteTagMembers,
      ),
      {
        urlParams: { tagId: tag_id },
        data: { member_ids },
        suppressToastrOnError: true,
      },
    );
    notification.info({
      message: 'Tag Deleted successfully',
      placement: 'bottomRight',
      icon: (
        <NaveeIcon.CheckGreen
          style={{
            color: 'var(--custom-green)',
            fontSize: 16,
            marginBottom: 8,
          }}
        />
      ),
      duration: 5,
    });
    success_action();
  } catch (err: any) {
    if (onError) {
      onError();
    }
    notification.error({
      message: err?.response?.data?.['error message'] ?? err.message,
      placement: 'bottomRight',
      duration: 5,
    });
  }
}

export default function* feedFooterSaga() {
  yield takeEvery(ADD_MEMBERS_TO_TAG, updateMembersForTags);
  yield takeEvery(ADD_TAGS_TO_LIST, addTagsToTaggableList);
  yield takeEvery(REMOVE_OBJECTS_FROM_TAG, deleteTag);
}
