import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import { Occurrences } from 'product-types/src/domain/occurences/Occurences';
import {
  ModerationReason,
  ModerationReasonRaw,
  moderationReasonSort,
} from 'product-types/src/domain/moderationReason/ModerationReason';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { AssociatedDuplicatedGroup } from 'product-types/src/domain/duplicatedGroup/AssociatedDuplicatedGroup';
import { transformUrl } from 'product-utils/src/urls/urls';
import * as Image from './Image';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';
import { AttributeModel, AttributeRaw } from '../attribute/Attribute';
import { HasAttributes } from '../attribute/HasAttributes';

export interface AccountAssociatedImageRaw {
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  id: number;
  image_label_color: string;
  image_link: string;
  image_status: string;
  qa_checked: boolean;
  validated: boolean;
  tags: Array<TagRaw>;
  moderation_method: string;
  is_online?: boolean;
  attributes?: {
    [key: string]: Array<AttributeRaw<any>>;
  };
}

export interface AccountAssociatedImageRawImageView {
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  id: number;
  image_status_color: string;
  picture_url: string;
  image_status: string;
  qa_checked: boolean;
  validated: boolean;
  tags: Array<TagRaw>;
  is_online: boolean;
  attributes?: {
    [key: string]: Array<AttributeRaw<any>>;
  };
}

export interface PostAssociatedImageRawImageView {
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  id: number;
  image_label_color: string;
  picture_url: string;
  is_online: boolean;
  qa_checked: boolean;
  validated: boolean;
  dup_group_id: number;
  dup_group_type: string;
  moderation_reasons: Array<ModerationReasonRaw>;
  tags: Array<TagRaw>;
  attributes?: {
    [key: string]: Array<AttributeRaw<any>>;
  };
}

export class AssociatedImage
  implements CanBeModeratable, HasModerationPage, Magnifiable, HasAttributes
{
  id: Image.Id;

  attributes: { expiry_date: AttributeModel<'date'>[] };

  url: string;

  duplicatedGroup: AssociatedDuplicatedGroup;

  moderationReason?: Array<ModerationReason>;

  moderation: Moderatable;

  isOnline?: boolean;

  occurences?: Occurrences;

  tags: Array<TagModel>;

  constructor(
    prop: Pick<
      AssociatedImage,
      | 'tags'
      | 'id'
      | 'duplicatedGroup'
      | 'moderation'
      | 'occurences'
      | 'url'
      | 'moderationReason'
      | 'attributes'
      | 'isOnline'
    >,
  ) {
    this.id = prop.id ?? NaN;
    this.url = transformUrl(prop.url || '');
    this.moderation = prop?.moderation || Moderatable.default;
    this.duplicatedGroup = prop.duplicatedGroup;
    this.occurences = prop.occurences;
    this.moderationReason = prop.moderationReason;
    this.tags = prop.tags;
    this.attributes = prop.attributes;
    this.isOnline = prop.isOnline ?? true;
  }

  get midSizeImage() {
    return this.url;
  }

  get idForModeration() {
    return this.duplicatedGroup?.id || NaN;
  }

  get linkToModerationPage() {
    return `/image/${this.duplicatedGroup?.id}`;
  }

  get queryParam() {
    return `duplicated_group_id=${this.duplicatedGroup?.id}`;
  }

  static createFromAccountAssociatedImage(prop: AccountAssociatedImageRaw) {
    return new AssociatedImage({
      id: prop.id,
      url: prop.image_link,
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditQaCheck: prop.can_edit_qa_check,
        canEditReasons: prop.can_edit_reasons,
        canEditValidation: prop.can_edit_validation,
        canRemoveValidation: prop.can_remove_validation,
        canViewQaCheck: prop.can_view_qa_check,
        canViewValidation: prop.can_view_validation,
        moderation: prop.image_status,
        moderationColor: prop.image_label_color,
        qaChecked: prop.qa_checked,
        validated: prop.validated,
        moderationMethod: prop.moderation_method,
      }),
      duplicatedGroup: new AssociatedDuplicatedGroup({
        id: prop.id,
        occurrences: Occurrences.default,
      }),
      isOnline: prop.is_online,
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      attributes: Object.keys(prop.attributes ?? {}).reduce(
        (acc, key) => {
          acc[key] = (prop.attributes ?? {})[key].map(
            AttributeModel.createDateFromRaw,
          );
          return acc;
        },
        { expiry_date: new Array<AttributeModel<'date'>>() },
      ),
    });
  }

  static createFromAccountAssociatedImageImageView(
    prop: AccountAssociatedImageRawImageView,
  ) {
    return new AssociatedImage({
      id: prop.id,
      url: prop.picture_url,
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditQaCheck: prop.can_edit_qa_check,
        canEditReasons: prop.can_edit_reasons,
        canEditValidation: prop.can_edit_validation,
        canRemoveValidation: prop.can_remove_validation,
        canViewQaCheck: prop.can_view_qa_check,
        canViewValidation: prop.can_view_validation,
        moderation: prop.image_status,
        moderationColor: prop.image_status_color,
        qaChecked: prop.qa_checked,
        validated: prop.validated,
        moderationMethod: null,
      }),
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      duplicatedGroup: new AssociatedDuplicatedGroup({
        id: prop.id,
        occurrences: Occurrences.default,
      }),
      isOnline: prop.is_online,
      attributes: Object.keys(prop.attributes ?? {}).reduce(
        (acc, key) => {
          acc[key] = (prop.attributes ?? {})[key].map(
            AttributeModel.createDateFromRaw,
          );
          return acc;
        },
        { expiry_date: new Array<AttributeModel<'date'>>() },
      ),
    });
  }

  static createFromPostAssociatedImageImageView(
    prop: PostAssociatedImageRawImageView,
  ) {
    return new AssociatedImage({
      id: prop.id,
      url: prop.picture_url,
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditQaCheck: prop.can_edit_qa_check,
        canEditReasons: prop.can_edit_reasons,
        canEditValidation: prop.can_edit_validation,
        canRemoveValidation: prop.can_remove_validation,
        canViewQaCheck: prop.can_view_qa_check,
        canViewValidation: prop.can_view_validation,
        moderation: prop.dup_group_type,
        moderationColor: prop.image_label_color,
        qaChecked: prop.qa_checked,
        validated: prop.validated,
        moderationMethod: null,
      }),
      duplicatedGroup: new AssociatedDuplicatedGroup({
        id: prop.dup_group_id,
        occurrences: Occurrences.default,
      }),
      isOnline: prop.is_online,
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      moderationReason: (prop.moderation_reasons || [])
        .map((reason) => ModerationReason.createReasonFromRaw(reason))
        .sort(moderationReasonSort),
      attributes: Object.keys(prop.attributes ?? {}).reduce(
        (acc, key) => {
          acc[key] = (prop.attributes ?? {})[key].map(
            AttributeModel.createDateFromRaw,
          );
          return acc;
        },
        { expiry_date: new Array<AttributeModel<'date'>>() },
      ),
    });
  }

  static sortByActive() {
    return 0;
  }
}
