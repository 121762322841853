import { Country, Zone } from 'product-types/src/domain/geo/Geo';
import { LabelRaw } from 'product-types/src/domain/label/Label';
import { ModerationReasonRaw } from 'product-types/src/domain/moderationReason/ModerationReason';
import { TagRaw } from 'product-types/src/domain/tag/Tag';
import { UserLightRawModel } from 'product-types/src/domain/user/UserLightModel';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { ExportTemplateRawModel } from 'product-types/src/domain/export/ExportTemplate';
import { ProductCategoryRaw } from 'product-types/src/domain/productCategory';
import { Insight } from 'product-types/src/domain/insight/insight';
import { FilterPresetsRaw } from 'product-types/src/domain/globalData/GlobalData';
import { RoleRaw } from 'product-types/src/domain/role/Role';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { Currency } from 'product-types/src/domain/Currency';
import { CrawlingDomainRaw } from 'product-types/src/domain/crawlingDomain/CrawlingDomain';
import { LanguageRaw } from 'product-types/src/domain/language/language';
import { ScrappingSource } from 'product-types/src/domain/scrappingSource/ScrappingSource';
import { FeatureFlagRaw } from 'product-types/src/domain/featureFlags/FeatureFlag';
import { Endpoint } from '../../../../endpoint';
import { WebsiteStatus } from 'product-types/src/domain/website/WebsiteStatus';

type OrganisationLabels = {
  post: Array<LabelRaw>;
  image: Array<LabelRaw>;
  account: Array<LabelRaw>;
};

type TagArray = Array<TagRaw>;

type Tags = {
  account: TagArray;
  cluster: TagArray;
  duplicated_group: TagArray;
  post: TagArray;
  poster: TagArray;
  upload_history: TagArray;
  website: TagArray;
};

export type TopWebsites = Array<{
  count: number;
  label: string;
  value: string | number;
}>;

export type GlobalDataResponse = {
  accounts_export_templates: Array<ExportTemplateRawModel>;
  categories: Array<ProductCategoryRaw>;
  labels: OrganisationLabels;
  filter_presets: FilterPresetsRaw;
  geography: Array<Country>;
  languages: Array<LanguageRaw>;
  insight_options: Array<Insight>;
  contact_types: Array<ContactMainInfo>;
  organisation_statuses: {
    [key: string]: string;
  };
  feature_flags: Array<FeatureFlagRaw>;
  geography_zones: Array<Zone>;
  moderation_reasons: Array<ModerationReasonRaw>;
  posts_export_templates: Array<ExportTemplateRawModel>;
  tags: Tags;
  top_websites: TopWebsites;
  users: Array<UserLightRawModel>;
  website_categories: Array<WebsiteCategory>;
  website_status: Array<WebsiteStatus>;
  scraping_sources: Array<ScrappingSource>;
  roles: Array<RoleRaw>;
  currencies: Array<Currency>;
  domains_available_for_scraping: Array<CrawlingDomainRaw>;
};

const globalData = new Endpoint<any, GlobalDataResponse>({
  url: '/api/me/main_info',
  method: 'GET',
  headers: {},
});

export default globalData;
