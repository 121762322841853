import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import {
  KeywordsCleanlinessFilterValue,
  readFilterFromQueryProps,
} from './KeywordsCleanlinessValue';

export interface KeywordsCleanlinessFilterQueryValue extends QueryValue {
  search_keyword: Array<string>;
}
export class KeywordsCleanlinessFilter implements Filter {
  uuid: string;

  label: string;

  value: KeywordsCleanlinessFilterValue;

  constructor(
    params?: Partial<
      Pick<KeywordsCleanlinessFilter, 'uuid' | 'label' | 'value'>
    >,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || KeywordsCleanlinessFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return [];
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.searchKeyword) {
      this.value = this.value.clearSearchKeyword();
    }
    return new KeywordsCleanlinessFilter(this);
  }

  displayFilterComponent() {
    return null;
  }

  component() {
    return null;
  }

  get queryFilterValue(): KeywordsCleanlinessFilterQueryValue {
    return {
      search_keyword: this.value.searchKeyword,
    };
  }

  static readFilterFromQuery(
    props?: readFilterFromQueryProps,
  ): KeywordsCleanlinessFilter {
    return new KeywordsCleanlinessFilter({
      value: KeywordsCleanlinessFilterValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    props: readFilterFromQueryProps,
  ): KeywordsCleanlinessFilter {
    return new KeywordsCleanlinessFilter({
      value: KeywordsCleanlinessFilterValue.readFromSavedFilter(
        savedFilter,
        props,
      ),
    });
  }
}
