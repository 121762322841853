import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { CommonDashboardRequestParams } from 'product-types/src/domain/dashboard/CommonDashboardRequestParams';
import { KeywordsSearchedCleanlinessScoreResponseModel } from 'product-types/src/domain/dashboard/DashboardKeywordsSearchedCleanlinessScoreModel';

export interface KeywordsSearchedCleanlinessScoreRequestParamsModel
  extends CommonDashboardRequestParams {
  search_keyword: Array<string>;
  website_id: Array<number | string>;
  website_category_id: Array<number | string>;
  website_category_id_to_exclude: Array<number | string>;
  dates_aggregation: boolean;
}

interface KeywordsSearchedCleanlinessScoreRequestModel
  extends EndpointRequests {
  params: KeywordsSearchedCleanlinessScoreRequestParamsModel;
}

const getKeywordsSearchedCleanlinessScore = new Endpoint<
  KeywordsSearchedCleanlinessScoreRequestModel,
  KeywordsSearchedCleanlinessScoreResponseModel
>({
  url: '/api/me/stats/keywords/cleanliness',
  method: 'GET',
  headers: {},
});

export default getKeywordsSearchedCleanlinessScore;
