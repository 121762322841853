import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import { Category } from 'product-types/src/domain/category/Category';
import {
  ModerationReason,
  ModerationReasonRaw,
  moderationReasonSort,
} from 'product-types/src/domain/moderationReason/ModerationReason';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { UserModel, UserRawModel } from 'product-types/src/domain/user/User';
import { Id } from 'product-types/src/domain/image/Image';
import {
  CommentModel,
  CommentRaw,
} from 'product-types/src/domain/comment/Comment';
import dayjs, { Dayjs } from 'dayjs';
import { transformUrl } from 'product-utils/src/urls/urls';
import { ImageModerationVendorModel } from './ImageModerationVendorModel';
import { AttributeModel, AttributeRaw } from '../attribute/Attribute';

export interface ImageModerationRawModel {
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  category_name: string;
  global_category_id: number | null;
  checked_by: UserRawModel | null;
  comments: Array<CommentRaw>;
  crawling_date: string;
  id: number;
  image_id: number;
  image_url: string;
  infringement_type: string;
  moderated_by: UserRawModel | null;
  moderation_date: string;
  moderation_method: string;
  moderation_reasons: Array<ModerationReasonRaw>;
  occurrences: number;
  product: any | null;
  qa_check_date: null | Date;
  qa_checked: boolean;
  tags: Array<TagRaw>;
  validated: boolean;
  validated_by: UserRawModel | null;
  validation_date: string;
  vendor_ids: Array<number>;
  vendor_occurrences: number;
  vendors: Array<{
    id: number;
    name: string;
  }>;
  attributes?: {
    [key: string]: Array<AttributeRaw<any>>;
  };
}

export class ImageModerationModel
  implements CanBeSelectable, CanBeModeratable, HasModerationPage
{
  moderation: Moderatable;

  id: Id;

  attributes: { expiry_date: AttributeModel<'date'>[] };

  imageId: number;

  url: string;

  vendors: Array<ImageModerationVendorModel>;

  moderationReasons: Array<ModerationReason>;

  crawlingDate?: Date;

  tags: Array<TagModel>;

  category: Category;

  globalCategoryId: number | null;

  comments: Array<CommentModel>;

  naveeScore: number;

  vendorOccurrences: number;

  constructor(
    prop: Pick<
      ImageModerationModel,
      | 'imageId'
      | 'crawlingDate'
      | 'naveeScore'
      | 'comments'
      | 'category'
      | 'tags'
      | 'attributes'
      | 'moderationReasons'
      | 'vendors'
      | 'url'
      | 'id'
      | 'moderation'
      | 'globalCategoryId'
      | 'vendorOccurrences'
    >,
  ) {
    this.moderation = prop?.moderation
      ? new Moderatable(prop.moderation)
      : Moderatable.default;
    this.id = prop?.id ?? NaN;
    this.url = transformUrl(prop.url);
    this.vendors = prop.vendors;
    this.moderationReasons = prop.moderationReasons;
    this.attributes = prop.attributes;
    this.tags = prop.tags;
    this.category = prop.category;
    this.comments = prop.comments;
    this.naveeScore = prop.naveeScore;
    this.imageId = prop.imageId;
    this.crawlingDate = prop.crawlingDate;
    this.globalCategoryId = prop.globalCategoryId;
    this.vendorOccurrences = prop.vendorOccurrences;
  }

  get formattedName() {
    return `IM#${this.id}`;
  }

  get title() {
    return `Image #${this.id}`;
  }

  get queryParam() {
    return `duplicated_group_id=${this.id}`;
  }

  get idForModeration() {
    return this.id;
  }

  get linkToRelatedPostsPostFeed() {
    return `/post?date_filter_type=crawling_date&interval=until_today&duplicated_group_id=${this.id}`;
  }

  get linkToModerationPage() {
    return `/image/${this.id}`;
  }

  static createFromImageModerationRawModel(prop: ImageModerationRawModel) {
    return new ImageModerationModel({
      id: prop.id,
      imageId: prop.image_id,
      crawlingDate:
        (prop.crawling_date && new Date(prop.crawling_date)) || undefined,
      naveeScore: prop.occurrences,
      attributes: Object.keys(prop.attributes ?? {}).reduce(
        (acc, key) => {
          acc[key] = (prop.attributes ?? {})[key].map(
            AttributeModel.createDateFromRaw,
          );
          return acc;
        },
        { expiry_date: new Array<AttributeModel<'date'>>() },
      ),
      category: {
        name: prop.category_name,
        id: NaN,
        illustration_picture_url: '',
        mean_price: NaN,
        organisation_id: NaN,
      },
      globalCategoryId: prop.global_category_id,
      comments: prop.comments.map(CommentModel.createFromRawModel),
      url: prop.image_url,
      vendors: prop.vendors.map(
        (v) =>
          new ImageModerationVendorModel({
            id: v?.id ?? null,
            name: v?.name ?? null,
          }),
      ),
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      moderationReasons: prop.moderation_reasons
        .map((reason) => ModerationReason.createReasonFromRaw(reason))
        .sort(moderationReasonSort),
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditValidation: prop.can_edit_validation,
        canRemoveValidation: prop.can_remove_validation,
        canEditQaCheck: prop.can_edit_qa_check,
        canViewQaCheck: prop.can_view_qa_check,
        canEditReasons: prop.can_edit_reasons,
        canViewValidation: prop.can_view_validation,
        moderationMethod: prop.moderation_method,
        validated: prop.validated,
        moderation: prop.infringement_type,
        moderationColor: '#ececef', // todo: fix calculating color based on stored values.
        qaChecked: prop.qa_checked,
        qaCheckedDate:
          (prop.qa_check_date &&
            (
              (dayjs as any).utc(prop.qa_check_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        moderatedDate:
          (prop.moderation_date &&
            (
              (dayjs as any).utc(prop.moderation_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        validatedDate:
          (prop.validation_date &&
            (
              (dayjs as any).utc(prop.validation_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        moderatedBy:
          (prop.moderated_by && UserModel.createFromRaw(prop.moderated_by)) ||
          undefined,
        qaCheckedBy:
          (prop.checked_by && UserModel.createFromRaw(prop.checked_by)) ||
          undefined,
        validatedBy:
          (prop.validated_by && UserModel.createFromRaw(prop.validated_by)) ||
          undefined,
      }),
      vendorOccurrences: prop.vendor_occurrences,
    });
  }
}
