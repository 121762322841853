import React from 'react';
import { Modal } from 'product-ui/src/components/organism/Modal';
import Typography from 'product-ui/src/components/atoms/Typography';

interface ConfirmationModalProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const ConfirmationModal = ({
  title,
  message,
  onConfirm,
  onCancel = () => {},
}: ConfirmationModalProps) => (
  <Modal
    open
    onClose={onCancel}
    buttons={{
      submit: { onClick: onConfirm, label: 'Confirm' },
      cancel: { onClick: onCancel },
    }}
    title={title}
  >
    <Typography variant="large" fontWeight="bold" textAlign="center">
      {message}
    </Typography>
  </Modal>
);
