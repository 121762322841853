import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { DateFilterValueEnum } from 'product-types/src/domain/date/Date';

dayjs.extend(localizedFormat);

export const DATE_FORMAT_INTERNAL = 'YYYY-MM-DD';
export const DATE_FORMAT_READABLE = 'DD MMM YYYY';
export const DATETIME_FORMAT_READABLE = 'DD MMM YYYY - LT ([GMT]Z)';
const TIME_FORMAT = 'HH:mm';
export const formatDateReadable = (date, withTime = false) =>
  dayjs(date).format(
    DATE_FORMAT_READABLE + (withTime ? ` ${TIME_FORMAT}` : ''),
  );
export const formatDate = (dayjsInstance: Dayjs) =>
  dayjs(dayjsInstance).format(DATE_FORMAT_INTERNAL);

export const formatDateWithTimezoneLocalFormat = (
  date: string | Date | dayjs.Dayjs,
) => dayjs(date).format(DATETIME_FORMAT_READABLE);

export function getStartDateBasedOnInterval(interval: DateFilterValueEnum) {
  switch (interval) {
    case DateFilterValueEnum.afterThreeMonths:
      return formatDate(dayjs().add(3, 'month'));
    case DateFilterValueEnum.oneDay:
      return formatDate(dayjs().subtract(1, 'day'));
    case DateFilterValueEnum.oneWeek:
      return formatDate(dayjs().subtract(1, 'week'));
    case DateFilterValueEnum.oneMonth:
      return formatDate(dayjs().subtract(1, 'month'));
    case DateFilterValueEnum.threeMonths:
      return formatDate(dayjs().subtract(3, 'month'));
    case DateFilterValueEnum.sixMonths:
      return formatDate(dayjs().subtract(6, 'month'));
    case DateFilterValueEnum.oneYear:
      return formatDate(dayjs().subtract(1, 'year'));
    case DateFilterValueEnum.previousMonth:
      return formatDate(dayjs().subtract(1, 'month').startOf('month'));
    case DateFilterValueEnum.thisMonth:
      return formatDate(dayjs().startOf('month'));
    case DateFilterValueEnum.nextMonth:
    case DateFilterValueEnum.nextTwoMonths:
    case DateFilterValueEnum.nextThreeMonths:
    case DateFilterValueEnum.nextSixMonths:
      return formatDate(dayjs().endOf('day'));
    default:
      return null;
  }
}

export function getEndDateBasedOnInterval(interval: DateFilterValueEnum) {
  switch (interval) {
    case DateFilterValueEnum.thisMonth:
      return formatDate(dayjs().endOf('month'));
    case DateFilterValueEnum.oneDay:
    case DateFilterValueEnum.oneWeek:
    case DateFilterValueEnum.oneMonth:
    case DateFilterValueEnum.threeMonths:
    case DateFilterValueEnum.sixMonths:
    case DateFilterValueEnum.oneYear:
    case DateFilterValueEnum.untilToday:
      return formatDate(dayjs().endOf('day'));
    case DateFilterValueEnum.previousMonth:
      return formatDate(dayjs().subtract(1, 'month').endOf('month'));
    case DateFilterValueEnum.nextTwoMonths:
      return formatDate(dayjs().add(2, 'month').endOf('day'));
    case DateFilterValueEnum.nextMonth:
      return formatDate(dayjs().add(1, 'month'));
    case DateFilterValueEnum.nextSixMonths:
      return formatDate(dayjs().add(6, 'month'));
    case DateFilterValueEnum.nextThreeMonths:
      return formatDate(dayjs().add(3, 'month'));
    default:
      return null;
  }
}

export function formatGraphXAxisDate(date: string) {
  return dayjs(date).format('MMM');
}

export function formatGraphTooltipDate(date: string) {
  return dayjs(date).format('MMMM YYYY');
}
