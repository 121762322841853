import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import { UserModel, UserRawModel } from 'product-types/src/domain/user/User';
import { DomainModel } from 'product-types/src/domain/domain/Domain';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { ContactRaw } from 'product-types/src/domain/contact/Contact';
import {
  ClusterFeedModel,
  ClusterFeedRawModel,
} from 'product-types/src/domain/cluster/ClusterFeedModel';
import { WebsiteDescriptionRaw } from 'product-types/src/domain/website/WebsiteDescription';
import { WebsiteStatus } from 'product-types/src/domain/website/WebsiteStatus';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';

export interface WebsiteFeedRawModel {
  account_contacts: Array<ContactRaw>;
  account_description: Array<WebsiteDescriptionRaw>;
  account_external_link: string;
  account_image_link: string;
  account_infringing_percentage: number;
  archive_link: string | null;
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  checked_by: UserRawModel | null;
  cluster: ClusterFeedRawModel | null;
  crawling_date: string | null;
  display_color: string;
  domain_name: string;
  domain_url: string;
  followers_count: number | null;
  geo: string | null;
  geo_country: string | null;
  id: string;
  infringement_type: string | null;
  is_desc_counterfeit: boolean | null;
  is_for_sale: boolean | null;
  median_price: number | null;
  moderated_by: UserRawModel | null;
  moderation_date: string | null;
  moderation_method: string | null;
  post_moderation_percentage: number;
  poster_id: number;
  poster_name: string;
  qa_check_date: string | null;
  qa_checked: boolean | null;
  rank: number;
  tags: Array<TagRaw>;
  taken_down: boolean;
  total_images_moderated: number;
  total_images_unmoderated: number;
  total_posts: number;
  total_posts_moderated: number;
  total_posts_unmoderated: number;
  validated: boolean | null;
  validated_by: UserRawModel | null;
  validation_date: string | null;
  website_category: string | null;
  website_id: number;
  status?: WebsiteStatus['value'];
  status_observations_consecutive_count: number;
  status_observed_last_at: string;
}

export class WebsiteFeedModel
  implements CanBeModeratable, CanBeSelectable, HasModerationPage, Magnifiable
{
  _id: string;

  moderation: Moderatable;

  imageLink: string;

  name: string;

  domain: DomainModel;

  rank: number;

  totalPosts: number;

  takenDown: boolean;

  cluster: ClusterFeedModel | null;

  tags: Array<TagModel>;

  status?: WebsiteStatus['value'];

  statusObservationsConsecutiveCount: number;

  statusObservedLastAt: string;

  constructor(
    prop: Pick<
      WebsiteFeedModel,
      | 'rank'
      | 'id'
      | 'moderation'
      | 'imageLink'
      | 'name'
      | 'domain'
      | 'totalPosts'
      | 'takenDown'
      | 'cluster'
      | 'tags'
      | 'status'
      | 'statusObservationsConsecutiveCount'
      | 'statusObservedLastAt'
    >,
  ) {
    this._id = prop.id;
    this.moderation = prop.moderation;
    this.imageLink = prop.imageLink;
    this.rank = prop.rank;
    this.name = prop.name;
    this.domain = prop.domain;
    this.totalPosts = prop.totalPosts;
    this.takenDown = prop.takenDown;
    this.cluster = prop.cluster;
    this.tags = prop.tags;
    this.status = prop.status;
    this.statusObservationsConsecutiveCount =
      prop.statusObservationsConsecutiveCount;
    this.statusObservedLastAt = prop.statusObservedLastAt;
  }

  get midSizeImage() {
    return this.imageLink;
  }

  get id() {
    return (this._id || '').slice(3);
  }

  get isPoster() {
    return false;
  }

  get isWebsite() {
    return true;
  }

  get queryParam() {
    return `account_website_id=${this.id}`;
  }

  get linkToModerationPage() {
    return `/website/${this.id}`;
  }

  static createFromWebsiteFeedRawModel(prop: WebsiteFeedRawModel) {
    return new WebsiteFeedModel({
      id: prop.id,
      rank: prop.rank,
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditQaCheck: prop.can_edit_qa_check,
        canEditReasons: prop.can_edit_reasons,
        canEditValidation: prop.can_edit_validation,
        canRemoveValidation: prop.can_remove_validation,
        canViewQaCheck: prop.can_view_qa_check,
        canViewValidation: prop.can_view_validation,
        moderatedBy:
          (prop.moderated_by && UserModel.createFromRaw(prop.moderated_by)) ||
          undefined,
        qaCheckedDate:
          (prop.qa_check_date && new Date(prop.qa_check_date)) || undefined,
        qaChecked: prop.qa_checked,
        validated: prop.validated,
        validatedBy:
          (prop.validated_by && UserModel.createFromRaw(prop.validated_by)) ||
          undefined,
        validatedDate:
          (prop.validation_date && new Date(prop.validation_date)) || undefined,
        moderation: prop.infringement_type,
        moderationColor: prop.display_color,
        moderationMethod: prop.moderation_method,
      }),
      imageLink: prop.account_image_link,
      name: prop.poster_name,
      domain: {
        name: prop.domain_name,
        url: prop.domain_url,
      },
      totalPosts: prop.total_posts,
      takenDown: prop.taken_down,
      cluster:
        prop.cluster && prop.cluster.id
          ? ClusterFeedModel.createFromClusterFeedRawModel(prop.cluster)
          : null,
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      status: prop.status,
      statusObservationsConsecutiveCount:
        prop.status_observations_consecutive_count,
      statusObservedLastAt: prop.status_observed_last_at,
    });
  }
}
