import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import keywordsSearched from './keywordsSearched';
import keywordsSearchedCleanlinessScore from './keywordsSearchedCleanlinessScore';
import keywordsFound from './keywordsFound';
import keywordsGraph from './keywordsGraph';
import cleanlinessScoreGraph from './cleanlinessScoreGraph';

export interface VisibilityEndpoints extends EndpointContainer {
  keywordsSearched: typeof keywordsSearched;
  keywordsSearchedCleanlinessScore: typeof keywordsSearchedCleanlinessScore;
  keywordsFound: typeof keywordsFound;
  keywordsGraph: typeof keywordsGraph;
  cleanlinessScoreGraph: typeof cleanlinessScoreGraph;
}

const visibility: VisibilityEndpoints = {
  keywordsSearched,
  keywordsSearchedCleanlinessScore,
  keywordsFound,
  keywordsGraph,
  cleanlinessScoreGraph,
  injectHTTPClient(client: HttpClient) {
    keywordsSearched.injectHTTPClient(client);
    keywordsSearchedCleanlinessScore.injectHTTPClient(client);
    keywordsFound.injectHTTPClient(client);
    keywordsGraph.injectHTTPClient(client);
    cleanlinessScoreGraph.injectHTTPClient(client);
  },
};

export default visibility;
