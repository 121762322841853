import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import { Tag, TagModel } from 'product-types/src/domain/tag/Tag';
import {
  TOGGLE_ELEMENT,
  ADD_MEMBERS_TO_TAG,
  REMOVE_OBJECTS_FROM_TAG,
  RESET_STATE,
  SET_ELEMENTS_TYPE,
  SET_FOOTER_FIELD,
  DESELECT_ELEMENTS,
  SELECT_ELEMENTS,
  UPDATE_SELECTED_TAGS,
  ADD_TAGS_TO_LIST,
} from './constants';
import { selectFilterAction } from '../FiltersBar/actions';
import { SelectedElementTypeEnum } from './reducer';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function deselectElements() {
  return {
    type: DESELECT_ELEMENTS,
  };
}

export function setSelectedElements(payload: SelectedElementTypeEnum) {
  return {
    type: SET_ELEMENTS_TYPE,
    payload,
  };
}

export function toggleSelectedElement(payload: CanBeSelectable) {
  return {
    type: TOGGLE_ELEMENT,
    payload,
  };
}

export function selectElement(payload: Array<CanBeSelectable> = []) {
  return {
    type: SELECT_ELEMENTS,
    payload,
  };
}

export interface AddTagToTaggableList {
  tags: Array<TagModel>;
  taggableList: Array<string>;
  tagType: 'post' | 'duplicated_group' | 'account' | 'cluster' | 'website';
  onSuccess?: () => void;
  onError?: () => void;
}

export function addTagToTaggableList({
  tagType,
  tags,
  taggableList,
  onSuccess,
  onError,
}: AddTagToTaggableList) {
  return {
    type: ADD_TAGS_TO_LIST,
    tagType,
    tags,
    taggableList,
    onSuccess,
    onError,
  };
}

export function addMembersToTagsAction({
  member_ids,
  tag,
  tag_type,
  type = ADD_MEMBERS_TO_TAG,
  success_action = null,
  errorAction = null,
  shouldRefreshTags = true,
}: {
  [key: string]: any;
  success_action?: (() => void) | null;
}) {
  return {
    type,
    member_ids,
    tag,
    tag_type,
    success_action,
    errorAction,
    shouldRefreshTags,
  };
}
export function setFeedFooterFieldAction(field, value) {
  return selectFilterAction(field, value, SET_FOOTER_FIELD);
}

export function removeObjectsFromTag({
  tag_id,
  member_ids,
  success_action,
  onError = undefined,
}) {
  return {
    type: REMOVE_OBJECTS_FROM_TAG,
    tag_id,
    member_ids,
    success_action,
    onError,
  };
}

export const updateSelectedTags = (tags: Array<Tag>) => ({
  type: UPDATE_SELECTED_TAGS,
  tags,
});
