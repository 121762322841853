/* eslint-disable no-console */
import queryString from 'query-string';
import { notification } from 'antd';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { PostFeedModel } from 'product-types/src/domain/post/PostFeedModel';
import {
  PostFeedDataModel,
  PostFeedResponseModel,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import {
  CustomError,
  CustomErrorFactory,
} from 'product-types/src/common/Error/CustomError';
import ProductMonitor from '../../types/network/Http/productMonitor/index';
import Network from '../../types/network/index';
import {
  loadPostsDataAction,
  loadPostsDataSuccessAction,
  RecrawlPostsProps,
  updatePostsCount,
  updateRelatedPosts,
  updateTakedownStatus as updateTakedownStatusAction,
} from './actions';
import {
  LOAD_HISTORICAL_ANALYSIS_DATA,
  LOAD_POSTS_COUNT,
  LOAD_POSTS_DATA,
  LOAD_RELATED_POSTS_DATA,
  RECRAWL_POST,
  UPDATE_TAKEN_DOWN_STATUS,
} from './constants';
import { setFeedFilterAction } from '../../layout/FiltersBar/actions';
import { TableParamsFilter } from '../../types/filters/AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { AppState } from '../../store/storeAccess';

function* loadHistoricalAnalysisData(action) {
  yield put(
    updateRelatedPosts(
      new FetchableData<PostFeedDataModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  try {
    const postsData: PostFeedResponseModel = yield call(
      ProductMonitor.endpoints.posts.historicalAnalysis.call.bind(
        ProductMonitor.endpoints.posts.historicalAnalysis,
      ),
      {
        params: Network.Post.getRequestParameter(action.pageState),
        urlParams: {
          id: action.pageState.id,
        },
      },
    );

    yield put(
      updateRelatedPosts(
        new FetchableData<PostFeedDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            currency_iso: postsData.currency_iso,
            total: postsData.total,
            posts: postsData.posts.map((post) =>
              PostFeedModel.createFromPostFeedRawModel(
                post,
                postsData.currency_iso,
              ),
            ),
          },
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateRelatedPosts(
        new FetchableData<PostFeedDataModel>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
  }
}

function* loadRelatedPostsData(action) {
  yield put(
    updateRelatedPosts(
      new FetchableData<PostFeedDataModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  try {
    const postsData: PostFeedResponseModel = yield call(
      ProductMonitor.endpoints.posts.getPosts.call.bind(
        ProductMonitor.endpoints.posts.getPosts,
      ),
      {
        data: Network.Post.getRequestParameter(action.pageState),
      },
    );

    yield put(
      updateRelatedPosts(
        new FetchableData<PostFeedDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            currency_iso: postsData.currency_iso,
            total: postsData.total,
            posts: postsData.posts.map((post) =>
              PostFeedModel.createFromPostFeedRawModel(
                post,
                postsData.currency_iso,
              ),
            ),
          },
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateRelatedPosts(
        new FetchableData<PostFeedDataModel>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* recrawlPosts(params: RecrawlPostsProps) {
  try {
    yield call(
      ProductMonitor.endpoints.upload.uploadPost.call.bind(
        ProductMonitor.endpoints.upload.uploadPost,
      ),
      {
        data: {
          global_label: '',
          global_tags: [],
          global_vendor: '',
          override: null,
          posts: params.posts.map((p) => ({
            url: p.url,
            label: p.label,
            tags: [],
          })),
        },
      },
    );

    params.success_action();
    notification.success({
      message: `The post${
        params.posts ? 's' : ''
      } has successfully been sent for recrawling. It will be updated shortly.`,
      description: `The post${
        params.posts ? 's' : ''
      } has successfully been sent for recrawling. It will be updated shortly.`,
      placement: 'bottomRight',
      duration: 15,
    });
  } catch (err) {
    console.error(err);
  }
}

function* loadPostData(action: ReturnType<typeof loadPostsDataAction>) {
  yield put(
    loadPostsDataSuccessAction(
      action.successAction,
      new FetchableData<PostFeedDataModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  const requestParameters = Network.Post.getRequestParameter(action.pageState);

  if (action.pageState.isExplorePage || action.pageState.isAccountView) {
    if (
      window.location.search.replace(/^\?/, '') !==
      queryString.stringify(requestParameters)
    ) {
      yield put(
        push(
          {
            search: queryString.stringify(requestParameters),
          },
          { navigation: true },
        ),
      );
    }
  }

  try {
    const postsData: PostFeedResponseModel = yield call(
      ProductMonitor.endpoints.posts.getPosts.call.bind(
        ProductMonitor.endpoints.posts.getPosts,
      ),
      {
        data: requestParameters,
        signal: action.abortController?.signal,
        suppressToastrOnError: true,
      },
    );

    yield put(
      loadPostsDataSuccessAction(
        action.successAction,
        new FetchableData<PostFeedDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            currency_iso: postsData.currency_iso,
            total: postsData.total,
            posts: postsData.posts.map((post) =>
              PostFeedModel.createFromPostFeedRawModel(
                post,
                postsData.currency_iso,
              ),
            ),
          },
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      loadPostsDataSuccessAction(
        action.successAction,
        new FetchableData<PostFeedDataModel>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* loadPostsCount(action: ReturnType<typeof loadPostsDataAction>) {
  yield put(
    updatePostsCount(
      new FetchableData<number>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  const requestParameters = Network.Post.getRequestParameter({
    ...action.pageState,
    retrieve_count: true,
    retrieve_objects: false,
  });

  try {
    const postsData: PostFeedResponseModel = yield call(
      ProductMonitor.endpoints.posts.getPosts.call.bind(
        ProductMonitor.endpoints.posts.getPosts,
      ),
      {
        data: requestParameters,
        signal: action.abortController?.signal,
        suppressToastrOnError: true,
      },
    );

    if ((requestParameters.offset || 0) > postsData.total) {
      const tableParams: TableParamsFilter = yield select((state: AppState) =>
        state.filters_bar.feed.currentFilters.find(
          (filter) => filter instanceof TableParamsFilter,
        ),
      );
      yield put(
        setFeedFilterAction(
          new TableParamsFilter({
            ...tableParams,
            value: tableParams.value.updatePagination(1, 0),
          }),
        ),
      );
    }

    yield put(
      updatePostsCount(
        new FetchableData<number>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: postsData.total,
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updatePostsCount(
        new FetchableData<number>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* updateTakendownStatus(
  props: ReturnType<typeof updateTakedownStatusAction>,
) {
  try {
    const result = yield call(
      ProductMonitor.endpoints.posts.updateTakedownStatus.call.bind(
        ProductMonitor.endpoints.posts.updateTakedownStatus,
      ),
      {
        data: {
          post_ids: props.post_ids,
          taken_down: props.taken_down,
        },
      },
    );
    notification.success({
      message: result.message,
      placement: 'bottomRight',
      duration: 3,
    });
    props.successAction();
  } catch (err: any) {
    if (err.status !== 403) {
      notification.error({
        message: CustomErrorFactory.create(err).message,
        placement: 'bottomRight',
        duration: 3,
      });
    }
    props.failureAction?.();
    console.error(err);
  }
}

export default function* postViewSaga() {
  yield takeLatest(LOAD_POSTS_DATA, loadPostData);
  yield takeLatest(LOAD_HISTORICAL_ANALYSIS_DATA, loadHistoricalAnalysisData);
  yield takeLatest(LOAD_RELATED_POSTS_DATA, loadRelatedPostsData);
  yield takeLatest(LOAD_POSTS_COUNT, loadPostsCount);
  yield takeLatest(RECRAWL_POST, recrawlPosts);
  yield takeLatest(UPDATE_TAKEN_DOWN_STATUS, updateTakendownStatus);
}
