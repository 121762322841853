import { UserFilterRoleEnum } from 'product-types/src/domain/user/UserFilterRole';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { FilterTypeEnum } from '../../AtomicFilters/Filter';

export enum UserFilterKey {
  moderatorId = 'moderator_id',
  qaCheckerId = 'qa_checker_id',
  validatorId = 'validator_id',
  commenterId = 'commenter_id',
  uploaderId = 'uploader_id',
}
const UrlMapping = [
  { key: UserFilterKey.moderatorId, role: UserFilterRoleEnum.moderator },
  { key: UserFilterKey.qaCheckerId, role: UserFilterRoleEnum.qa },
  { key: UserFilterKey.validatorId, role: UserFilterRoleEnum.validator },
  { key: UserFilterKey.commenterId, role: UserFilterRoleEnum.commenter },
  { key: UserFilterKey.uploaderId, role: UserFilterRoleEnum.uploader },
];

export class UserFilterValueModel {
  user: UserLightModel;

  role: UserFilterRoleEnum = UserFilterRoleEnum.commenter;

  constructor(params: Pick<UserFilterValueModel, 'user' | 'role'>) {
    this.user = params.user;
    this.role = params.role;
  }

  get filterType() {
    switch (this.role) {
      case UserFilterRoleEnum.commenter:
        return FilterTypeEnum.commetedBy;
      case UserFilterRoleEnum.moderator:
        return FilterTypeEnum.moderatedBy;
      case UserFilterRoleEnum.qa:
        return FilterTypeEnum.checkedBy;
      case UserFilterRoleEnum.validator:
        return FilterTypeEnum.validatedBy;
      case UserFilterRoleEnum.uploader:
        return FilterTypeEnum.uploadedBy;
      default:
        return FilterTypeEnum.moderatedBy;
    }
  }

  get urlSearchKey() {
    return UrlMapping.find((m) => m.role === this.role)?.key ?? '';
  }
}
