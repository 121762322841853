import React from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import HasChildren from 'product-ui/src/components/atoms/Interfaces/HasChildren';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import enGB from 'antd/locale/en_GB';

dayjs.locale('en-gb');

export const defaultThemeConfig: ThemeConfig = {
  components: {
    Input: {
      fontFamily: 'Satoshi',
      activeBorderColor: 'var(--primary-green)',
      fontSize: 14,
      fontSizeLG: 16,
      hoverBorderColor: 'var(--neutral-grey-700)',
      paddingBlock: 12,
      controlHeightLG: 40,
      paddingInline: 12,
      paddingBlockLG: 11,
      paddingInlineLG: 11,
      activeShadow:
        '0px -1px 1px 0px rgba(218, 227, 225, 0.15) inset, 0px 2px 2px 0px rgba(218, 227, 225, 0.10)',
      errorActiveShadow: '',
    },
    InputNumber: {
      fontFamily: 'Satoshi',
      fontSize: 14,
      fontSizeLG: 16,
      activeBorderColor: 'var(--primary-green)',
      hoverBorderColor: 'var(--neutral-grey-700)',
      paddingBlock: 12,
      paddingInline: 12,
      paddingBlockLG: 11,
      paddingInlineLG: 11,
      activeShadow:
        '0px -1px 1px 0px rgba(218, 227, 225, 0.15) inset, 0px 2px 2px 0px rgba(218, 227, 225, 0.10)',
      errorActiveShadow: '',
    },
    Pagination: {
      itemBg: 'var(--white)',
      controlHeight: 32,
      controlHeightSM: 32,
      controlHeightLG: 32,
      controlHeightXS: 32,
      itemActiveBg: 'var(--primary-green)',
      controlOutlineWidth: 1,
      controlOutline: 'var(--primary-border)',
      colorPrimary: 'var(--neutral-grey-700)',
      colorPrimaryHover: 'var(--primary-grey-700)',
      colorBorderSecondary: 'var(--primary-border)',
      colorBorder: 'var(--primary-border)',
      lineType: 'solid',
      lineWidth: 1,
    },
    Checkbox: {
      colorPrimaryHover: 'var(--primary-green)',
      colorBorder: 'var(--primary-border)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryBorder: 'var(--primary-border)',
      colorWhite: 'var(--primary-black)',
      controlInteractiveSize: 24,
      borderRadius: 8,
      borderRadiusLG: 8,
      borderRadiusSM: 8,
      borderRadiusXS: 8,
      fontFamily: 'Satoshi',
      fontSize: 14,
      lineHeight: 1.428571428571429,
      paddingContentVertical: 8,
      paddingContentHorizontal: 4,
      margin: 8,
    },
    Select: {
      optionPadding: '0.75em 1em',
      optionActiveBg: 'var(--primary-hover)',
      optionSelectedBg: 'var(--primary-green)',
      paddingContentVertical: 12,
      paddingContentHorizontal: 16,
      paddingContentVerticalSM: 12,
      paddingContentHorizontalSM: 16,
      paddingContentVerticalLG: 12,
      paddingContentHorizontalLG: 16,
      borderRadius: 4,
      controlHeight: 48,
      controlHeightLG: 48,
      controlHeightSM: 40,
      fontSize: 14,
      fontSizeLG: 16,
      optionHeight: 48,
      controlHeightXS: 48,
      singleItemHeightLG: 48,
      colorBorder: 'var(--primary-border)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryHover: 'var(--primary-black)',
      multipleSelectorBgDisabled: 'var(--neutral-grey-100)',
      colorTextDisabled: 'var(--neutral-grey-400)',
      colorIcon: 'var(--neutral-grey-500)',
      controlPaddingHorizontalSM: 12,
      controlPaddingHorizontal: 12,
    },
    DatePicker: {
      controlHeight: 48,
      cellWidth: 37,
      inputFontSize: 16,
      inputFontSizeLG: 16,
      inputFontSizeSM: 16,
      paddingBlock: 12,
      paddingBlockLG: 11,
      paddingInline: 12,
      paddingInlineLG: 11,
      paddingInlineSM: 11,
      lineHeight: '24px' as never as number,
      lineHeightLG: '24px' as never as number,
      lineHeightSM: '24px' as never as number,
      controlHeightLG: 48,
      cellHeight: 32,
      colorTextLightSolid: 'black',
      colorPrimary: 'var(--primary-green)',
      activeBorderColor: 'var(--primary-green)',
      hoverBorderColor: 'var(--primary-black)',
      cellActiveWithRangeBg: 'var(--mint-green-lighter10)',
    },
    Form: {
      itemMarginBottom: 0,
      inlineItemMarginBottom: 0,
    },
    Tree: {
      nodeHoverBg: 'var(--primary-hover)',
      nodeSelectedBg: 'var(--primary-green)',
      titleHeight: 40,
    },
    Modal: {
      contentBg: 'var(--white)',
      padding: '24px',
      paddingLG: '24px',
      paddingSM: '24px',
      paddingMD: '24px',
      paddingXS: '24px',
      paddingXL: '24px',
      paddingXXS: '24px',
      controlPaddingHorizontal: '24px',
      paddingContentHorizontal: '24px',
      paddingContentHorizontalLG: '24px',
      paddingContentHorizontalSM: '24px',
      controlPaddingHorizontalSM: '24px',
      paddingContentVertical: '24px',
      paddingContentVerticalLG: '24px',
      paddingContentVerticalSM: '24px',
      footerBg: 'transparent',
      margin: '24px',
      marginLG: '24px',
      marginSM: '24px',
      marginMD: '24px',
      marginXL: '24px',
      marginXS: '24px',
      marginXXL: '24px',
      marginXXS: '24px',
      borderRadiusOuter: 16,
      borderRadius: 16,
      borderRadiusLG: 16,
      borderRadiusSM: 16,
      borderRadiusXS: 16,
    },
    Tabs: {
      inkBarColor: 'var(--primary-green)',
      itemColor: 'var(--grey-grey-500)',
      itemHoverColor: 'var(--custom-green)',
      itemSelectedColor: 'var(--grey-grey-800)',
      fontFamily: 'Satoshi',
      fontWeightStrong: 700,
      horizontalItemPadding: '20px',
      horizontalItemGutter: 0,
    },
    Menu: {
      itemActiveBg: 'var(--primary-hover)',
      itemSelectedBg: 'var(--primary-hover)',
      itemSelectedColor: 'var(--grey-grey-800)',
    },
    Spin: {
      colorPrimary: '#00f380',
    },
    Table: {
      fontSize: 14,
      fontSizeSM: 14,
      fontSizeLG: 14,
      fontSizeXL: 14,
      borderColor: 'var(--primary-border)',
      fontWeightStrong: 700,
      fontFamily: 'Satoshi',
      colorPrimaryActive: 'var(--primary-green)',
      lineWidth: 0.5,
      colorTextHeading: 'var(--neutral-grey-600)',
      lineHeightLG: 1.5,
      lineHeightSM: 1.5,
      footerBg: 'var(--white)',
      lineHeight: 1.5,
      rowExpandedBg: 'var(--primary-bg)',
      rowHoverBg: 'var(--green-tints-green-1)',
      padding: 16,
      cellPaddingInline: 8,
    },
    Layout: {
      bodyBg: 'var(--primary-bg)',
    },
    Upload: {
      borderRadiusLG: 10,
      colorBorder: 'var(--green-tints-green-5)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryHover: 'var(--green-tints-green-7)',
    },
    Tooltip: {
      colorBgSpotlight: 'var(--neutral-grey-800)',
      colorText: 'var(--white)',
      colorTextLightSolid: 'var(--white)',
      zIndexPopup: 10000000,
      borderRadius: 4,
      borderRadiusLG: 4,
      borderRadiusSM: 4,
      borderRadiusOuter: 4,
      borderRadiusXS: 4,
      fontFamily: 'Satoshi',
      sizePopupArrow: 16,
      fontSize: 12,
      lineHeight: 1.5,
    },
    Collapse: {
      headerPadding: '0',
      contentPadding: '0 !important',
    },
    Transfer: {
      controlItemBgHover: 'var(--primary-hover)',
      controlItemBgActive: 'var(--primary-hover)',
      controlItemBgActiveHover: 'var(--green-tints-green-3)',
    },
  },
  token: {
    borderRadius: 4,
    boxShadow: 'none',
    colorBorder: 'var(--primary-border)',
    fontFamily: 'Satoshi',
  },
};
export const AntdConfigProvider = ({
  children,
  popupContainer,
}: HasChildren & { popupContainer?: HTMLElement }) => (
  <ConfigProvider
    getPopupContainer={popupContainer && (() => popupContainer)}
    theme={defaultThemeConfig}
    locale={enGB}
  >
    {children}
  </ConfigProvider>
);
