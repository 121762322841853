import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { UserFilterRoleElement } from 'product-types/src/domain/user/UserFilterRole';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { UserFilterValue as UserFilterValueComponent } from '../../../../components/Filters/UserFilter/UserFilterValue';
import { UserFilter as UserFilterComponent } from '../../../../components/Filters/UserFilter/index';
import { UserFilterValue, readFilterFromQueryProps } from './UserFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter } from '../../AtomicFilters/Filter';
import { UserFilterKey } from './UserFilterValueModel';

export interface UserFilterQueryValue extends QueryValue {
  [UserFilterKey.commenterId]?: Array<number>;
  [UserFilterKey.moderatorId]?: Array<number>;
  [UserFilterKey.qaCheckerId]?: Array<number>;
  [UserFilterKey.validatorId]?: Array<number>;
  [UserFilterKey.uploaderId]?: Array<number>;
}

export class UserFilter implements Filter {
  uuid: string;

  label: string;

  value: UserFilterValue;

  constructor(params?: Partial<Pick<UserFilter, 'uuid' | 'label' | 'value'>>) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || UserFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.users.map((user) => ({
      name: user.filterType,
      value: user,
      uuid: this.uuid,
      key: this.uuid + user.user.id + user.role,
    }));
  }

  get component() {
    return UserFilterComponent;
  }

  get displayFilterComponent() {
    return UserFilterValueComponent;
  }

  get queryFilterValue(): UserFilterQueryValue {
    return this.value.users.reduce((acc, user) => {
      if (!acc[user.urlSearchKey]) {
        acc[user.urlSearchKey] = [];
      }
      acc[user.urlSearchKey].push(user.user.id);
      return acc;
    }, {});
  }

  removeFilterValue(removableUser: any) {
    this.value = this.value.removeUser(removableUser.value);
    return new UserFilter(this);
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
    options: Array<UserFilterRoleElement>,
  ): UserFilter {
    return new UserFilter({
      value: UserFilterValue.readFilterFromQuery(props, options),
    });
  }

  static readFilterFromSavedFitler(
    savedFilter: SavedFilterModel,
    options: Array<UserFilterRoleElement>,
    props: readFilterFromQueryProps,
  ): UserFilter {
    return new UserFilter({
      value: UserFilterValue.readFromSavedFilter(savedFilter, options, props),
    });
  }
}
