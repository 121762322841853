import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import {
  ModerationReason,
  ModerationReasonRaw,
} from 'product-types/src/domain/moderationReason/ModerationReason';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { UserModel, UserRawModel } from 'product-types/src/domain/user/User';
import { Text } from 'product-types/src/domain/text/Text';
import { ItemPrice } from 'product-types/src/domain/price/ItemPrice';
import { ListedBrandInfo } from 'product-types/src/domain/listedBrandInfo/ListedBrandInfo';
import { Poster, PosterPostFeed } from 'product-types/src/domain/poster/Poster';
import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import {
  HistoricalPrice,
  HistoricalPriceRaw,
} from 'product-types/src/domain/historicalPrice/HistoricalPrice';
import { ContactRaw } from 'product-types/src/domain/contact/Contact';
import { WebsiteRaw } from 'product-types/src/domain/website/Website';
import { Cluster } from 'product-types/src/domain/cluster/Cluster';
import {
  AssociatedImage,
  PostAssociatedImageRawImageView,
} from 'product-types/src/domain/image/AssociatedImage';
import { TakedownModel } from 'product-types/src/domain/takedownStatus/TakedownModel';
import dayjs from 'dayjs';
import { CommentModel, CommentRaw } from '../comment/Comment';
import { PostModerationReason } from './PostModerationReason';
import { TakenDownable } from '../../common/TakenDownable/TakenDownable';

export interface PostModerationRawModel {
  archive_link: string;
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_change_category: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  global_category_id: number | null;
  checked_by: null | UserRawModel;
  cluster: null | any;
  comments: Array<CommentRaw>;
  consecutive_down_results: number;
  contact_details: Array<ContactRaw>;
  country_name: string;
  crawling_date: string;
  currency_iso: string;
  description: string;
  is_recrawlable: boolean;
  historical_price: Array<HistoricalPriceRaw>;
  has_listed_brand_info: boolean;
  id: number;
  images: Array<PostAssociatedImageRawImageView>;
  impact_score: number;
  infringement: boolean;
  infringement_type: string;
  infringing_account: null | any;
  is_desc_counterfeit: boolean | null;
  is_for_sale: boolean;
  is_product_used: boolean;
  last_activity: null | string;
  last_take_down_check_at: null | string;
  link: string;
  listed_brand: null | string;
  median_price: number;
  moderated_by: null | UserRawModel;
  moderation_date: string;
  moderation_method: string;
  moderation_reasons: {
    [key: string]: ModerationReasonRaw;
  };
  navee_score: number;
  organisation_currency_price: number | null;
  organisation_id: number;
  original_currency: null | string;
  original_price: null | number;
  payload: null | any;
  poster: null | PosterPostFeed;
  posting_date: null | string;
  price_discrepancy: number | null;
  qa_check_date: string;
  qa_checked: boolean;
  rank: number;
  source: number;
  source_language: null | string;
  stock_count: number;
  tags: Array<TagRaw>;
  taken_down: boolean;
  title: string;
  translated_description: string;
  translated_title: string;
  validated: boolean;
  validated_by: UserRawModel | null;
  validation_date: string;
  website: WebsiteRaw;
}

export class PostModerationModel
  implements CanBeModeratable, HasModerationPage, TakenDownable
{
  archiveLink: string;

  link: string;

  moderation: Moderatable;

  moderationReasons: Array<ModerationReason>;

  tags: Array<TagModel>;

  id: number;

  comments: Array<CommentModel>;

  images: Array<AssociatedImage>;

  isRecrawlable: boolean;

  brandInfo: ListedBrandInfo;

  takenDown?: boolean | null;

  category: number | null;

  impactScore: number;

  website: WebsiteRaw;

  text: Text;

  countryName: string;

  poster: Poster | null;

  stockCount: number;

  crawlingDate?: dayjs.Dayjs;

  historicalPrice: Array<HistoricalPrice>;

  currentPrice: ItemPrice;

  priceDiscrepancy: number | null;

  originalPrice: ItemPrice;

  takeDown: TakedownModel;

  medianPrice: ItemPrice;

  naveeScore: number;

  cluster: Cluster | null;

  postModeration: PostModerationReason;

  constructor(
    prop: Pick<
      PostModerationModel,
      | 'takeDown'
      | 'link'
      | 'archiveLink'
      | 'category'
      | 'historicalPrice'
      | 'crawlingDate'
      | 'brandInfo'
      | 'poster'
      | 'priceDiscrepancy'
      | 'originalPrice'
      | 'impactScore'
      | 'currentPrice'
      | 'isRecrawlable'
      | 'medianPrice'
      | 'takenDown'
      | 'countryName'
      | 'cluster'
      | 'naveeScore'
      | 'postModeration'
      | 'stockCount'
      | 'moderation'
      | 'moderationReasons'
      | 'tags'
      | 'text'
      | 'id'
      | 'website'
      | 'comments'
      | 'images'
    >,
  ) {
    this.moderation = prop.moderation;
    this.moderationReasons = prop.moderationReasons;
    this.takeDown = prop.takeDown;
    this.poster = prop.poster;
    this.tags = prop.tags;
    this.category = prop.category;
    this.archiveLink = prop.archiveLink;
    this.link = prop.link;
    this.id = prop.id;
    this.isRecrawlable = prop.isRecrawlable;
    this.impactScore = prop.impactScore;
    this.comments = prop.comments;
    this.images = prop.images;
    this.brandInfo = prop.brandInfo;
    this.website = prop.website;
    this.text = prop.text;
    this.historicalPrice = prop.historicalPrice;
    this.stockCount = prop.stockCount;
    this.naveeScore = prop.naveeScore;
    this.takenDown = prop.takenDown;
    this.cluster = prop.cluster;
    this.countryName = prop.countryName;
    this.currentPrice = prop.currentPrice;
    this.originalPrice = prop.originalPrice;
    this.priceDiscrepancy = prop.priceDiscrepancy;
    this.medianPrice = prop.medianPrice;
    this.postModeration = prop.postModeration;
    this.crawlingDate = prop.crawlingDate;
  }

  get formattedName() {
    return `PO#${this.id}`;
  }

  get title() {
    return `Post #${this.id}`;
  }

  get linkToModerationPage() {
    return `/post/${this.id}`;
  }

  get posterUrl() {
    if (!this.poster?.id) {
      return '';
    }
    return `/account/${this.poster?.id}`;
  }

  get websiteUrl() {
    if (!this.website?.id) {
      return '';
    }
    return `/website/${this.website?.id}`;
  }

  get accountUrl() {
    return this.posterUrl || this.websiteUrl;
  }

  static createFromRawModel(prop: PostModerationRawModel) {
    return new PostModerationModel({
      archiveLink: prop.archive_link,
      takeDown: TakedownModel.createFromTakedownRawModel(prop),
      link: prop.link,
      stockCount: prop.stock_count,
      naveeScore: prop.navee_score,
      isRecrawlable: prop.is_recrawlable,
      impactScore: prop.impact_score,
      priceDiscrepancy: prop.price_discrepancy,
      historicalPrice: prop.historical_price.map(
        (hp) =>
          new HistoricalPrice({
            date: new Date(hp.date),
            price: hp.price,
          }),
      ),
      category: prop.global_category_id,
      countryName: prop.country_name ?? 'Global',
      crawlingDate:
        (prop.crawling_date && dayjs(prop.crawling_date)) || undefined,
      currentPrice: new ItemPrice({
        currency: prop.currency_iso,
        price: prop.organisation_currency_price,
      }),
      brandInfo: new ListedBrandInfo({
        brand: prop.listed_brand ?? '',
        hasInfo: prop.has_listed_brand_info,
      }),
      medianPrice: new ItemPrice({
        currency: prop.currency_iso,
        price: prop.median_price,
      }),
      originalPrice: new ItemPrice({
        currency: prop.original_currency,
        price: prop.original_price,
      }),
      postModeration: new PostModerationReason({
        isDescCounterfeit: prop.is_desc_counterfeit ?? false,
        isForSale: prop.is_for_sale,
        isProductUsed: prop.is_product_used,
      }),
      takenDown: prop.taken_down,
      poster: prop.poster
        ? new Poster({
            clusterInfringement: prop.poster?.cluster_infringement,
            followersCount: prop.poster?.followers_count,
            geo: prop.poster?.geo,
            id: prop.poster?.id,
            labelId: prop.poster.label_id,
            localInfringement: prop.poster?.local_infringement || 0,
            priceDiscrepancy: prop.price_discrepancy || 0,
            name: prop.poster?.name,
            numberOfModeratedPostsGlobally:
              prop.poster?.number_of_moderated_posts_globally,
            numberOfPosts: prop.poster?.number_of_posts,
            otherOrganisationsInfringement:
              prop.poster?.other_organisations_infringement,
            recidivism: prop.poster?.recidivism,
          })
        : null,
      cluster: prop.cluster
        ? new Cluster({
            contactInfo: prop.cluster.contact_info,
            description: prop.cluster.description,
            estimatedGeo: prop.cluster.estimated_geo,
            id: prop.cluster.id,
            imageLink: prop.cluster.image_link,
            infringementPercentage: prop.cluster.infringement_percentage,
            moderationPercentage: prop.cluster.moderation_percentage,
            name: prop.cluster.name,
            rank: prop.cluster.rank,
            totalPosts: prop.cluster.total_posts,
          })
        : null,
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditQaCheck: prop.can_edit_qa_check,
        canChangeCategory: prop.can_change_category,
        canEditReasons: prop.can_edit_reasons,
        canEditValidation: prop.can_edit_validation,
        canRemoveValidation: prop.can_remove_validation,
        canViewQaCheck: prop.can_view_qa_check,
        canViewValidation: prop.can_view_validation,
        moderationMethod: prop.moderation_method,
        moderation: prop.infringement_type,
        moderationColor: 'transparent',
        qaChecked: prop.qa_checked,
        validated: prop.validated,
        qaCheckedDate:
          (prop.qa_check_date &&
            (
              (dayjs as any).utc(prop.qa_check_date).local() as dayjs.Dayjs
            ).toDate()) ||
          undefined,
        moderatedDate:
          (prop.moderation_date &&
            (
              (dayjs as any).utc(prop.moderation_date).local() as dayjs.Dayjs
            ).toDate()) ||
          undefined,
        validatedDate:
          (prop.validation_date &&
            (
              (dayjs as any).utc(prop.validation_date).local() as dayjs.Dayjs
            ).toDate()) ||
          undefined,
        moderatedBy:
          (prop.moderated_by && UserModel.createFromRaw(prop.moderated_by)) ||
          undefined,
        qaCheckedBy:
          (prop.checked_by && UserModel.createFromRaw(prop.checked_by)) ||
          undefined,
        validatedBy:
          (prop.validated_by && UserModel.createFromRaw(prop.validated_by)) ||
          undefined,
      }),
      website: prop.website,
      images: prop.images.map(
        AssociatedImage.createFromPostAssociatedImageImageView,
      ),
      id: prop.id,
      comments: prop.comments.map(CommentModel.createFromRawModel),
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      text: new Text({
        description: prop.description,
        descriptionTranslated: prop.translated_description,
        title: prop.title,
        titleTranslated: prop.translated_title,
        sourceLanguage: prop.source_language || undefined,
      }),
      moderationReasons: Object.values(prop.moderation_reasons).map((reason) =>
        ModerationReason.createReasonFromRaw(reason),
      ),
    });
  }
}
