import React from 'react';
import { notification } from 'antd';
import { goBack, push } from 'redux-first-history';
import queryString from 'query-string';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  CommentModel,
  CommentRaw,
} from 'product-types/src/domain/comment/Comment';
import {
  AccountModerationModel,
  AccountModerationRawModel,
} from 'product-types/src/domain/account/AccountModerationModel';
import { AccountFeedModel } from 'product-types/src/domain/account/AccountFeedModel';

import { RelatedAccount } from 'product-types/src/domain/account/RelatedAccount';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { Image } from 'product-types/src/domain/Domain';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import {
  AccountAllRelatedIdsResponseModel,
  AccountFeedDataModel,
  AccountFeedResponseModel,
  AssociatedImagesAccount,
  DuplicatedGroupResponseModel,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Contact } from 'product-types/src/domain/contact/Contact';
import { SelectedElementTypeEnum } from '../../layout/FeedFooter/reducer';
import { SELECT_FILTER } from '../../layout/FiltersBar/constants';
import makeSelectLoginPage from '../LoginPage/selectors';
import { LoginPageState } from '../LoginPage/reducer';
import { buildALinkWithOrgId } from '../../hooks/useNavigation';
import { RelatedAccountsResponse } from '../../types/network/Http/productMonitor/endpoints/accounts/relatedAccounts';
import Network from '../../types/network';
import {
  loadAccountImagesAction,
  loadAccountResourcesAction,
  loadAccountsDataSuccessAction,
  updateAccount,
  updateAccountCommentAction,
  updateAccountModerationQueue,
  updateRelatedAccounts,
  updateRelatedAccountsTotal,
  loadAccountComments,
  refreshAccountAction,
  updateAccountImagesAction,
  UpdateAccountTakedownStatusProps,
  AccountsForRecrawl,
  updateRelatedAccountIdsAction,
} from './actions';
import { selectFilterAction } from '../DashboardPage/actions';

import {
  LOAD_ACCOUNTS_DATA,
  LOAD_IMAGES_ACCOUNT,
  LOAD_NEXT_ACCOUNT_TO_MODERATE,
  LOAD_PREV_ACCOUNT_TO_MODERATE,
  PERFORM_ACCOUNT_MODERATION,
  REFRESH_ACCOUNT,
  SET_GEO_ACCOUNT,
  DEL_GEO_ACCOUNT,
  LOAD_COMMENTS_ACCOUNT,
  LOAD_RELATED_ACCOUNTS,
  MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE,
  LOAD_ACCOUNT_RESOURCES,
  LOAD_RELATED_POSTS_ACCOUNT_SUCCESS,
  ADD_COMMENT_ACCOUNT,
  DELETE_COMMENT_ACCOUNT,
  RECRAWL_ACCOUNT,
  LOAD_ACCOUNT_RELATED_POSTS,
  UPDATE_CONTACT_INFORMATION_ACCOUNT,
  UPDATE_ACCOUNT_TAKEN_DOWN_STATUS,
  LOAD_ALL_RELATED_ACCOUNT_IDS,
} from './constants';
import ProductMonitor from '../../types/network/Http/productMonitor';
import { FeedRequestParameter } from '../../types/network/Feed/Feed';
import { AppState } from '../../store/storeAccess';
import { loadPostsDataAction } from '../PostViewContainer/actions';
import { setFeedFilterAction } from '../../layout/FiltersBar/actions';
import { TableParamsFilter } from '../../types/filters/AtomicFiltersImplementation/TableParams/TableParamsFilter';

export function* loadNextAccountToModerate(action) {
  yield put(
    updateAccount(
      new FetchableData<AccountModerationModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(selectFilterAction('isModeration', true, SELECT_FILTER));

  let fn =
    ProductMonitor.endpoints.accounts.accountsModeration.nextAccountToModerate.call.bind(
      ProductMonitor.endpoints.accounts.accountsModeration
        .nextAccountToModerate,
    );
  if (action.type === LOAD_PREV_ACCOUNT_TO_MODERATE) {
    fn =
      ProductMonitor.endpoints.accounts.accountsModeration.prevAccountToModerate.call.bind(
        ProductMonitor.endpoints.accounts.accountsModeration
          .prevAccountToModerate,
      );
  }

  const {
    skipNavigation = false,
    abortController = undefined,
    ...data
  } = action.state;
  try {
    const account_moderation_data = yield call(fn, {
      data,
      signal: abortController?.signal,
    });

    const account = AccountModerationModel.createFromRawModel(
      account_moderation_data.account,
    );

    const loginPage: LoginPageState = yield select(makeSelectLoginPage());
    if (!loginPage.currentUser?.data?.organisation?.uid) {
      throw new Error('No organisation id');
    }
    if (!skipNavigation) {
      if (action.type === LOAD_PREV_ACCOUNT_TO_MODERATE) {
        yield put(goBack());
      } else {
        yield put(
          push(
            buildALinkWithOrgId(
              loginPage.currentUser?.data?.organisation?.uid,
              `${account.linkToModerationPage}/moderation`,
            ),
          ),
        );
      }
    }
    yield put(
      updateAccount(
        new FetchableData<AccountModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: account,
          error: null,
        }),
      ),
    );
    yield put(
      updateAccountModerationQueue({
        account_moderation_index:
          account_moderation_data.account_moderation_index,
        number_of_accounts_to_moderate:
          account_moderation_data.number_of_accounts_to_moderate,
      }),
    );
    yield call(loadAccountResources, {
      skipAccountCall: true,
      accountId: account._id,
      type: '',
    });
  } catch (error: any) {
    yield put(selectFilterAction('isModeration', false, SELECT_FILTER));
    // Detect whether the error comes from a 404
    if (error?.response?.status === 404) {
      yield put(
        updateAccount(
          new FetchableData<AccountModerationModel>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: CustomErrorFactory.create(error),
          }),
        ),
      );
    } else {
      yield put(
        updateAccount(
          new FetchableData<AccountModerationModel>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: CustomErrorFactory.create(error),
          }),
        ),
      );
    }
  }
}

function* recrawlAccounts(params: AccountsForRecrawl) {
  try {
    yield call(
      ProductMonitor.endpoints.upload.uploadAccount.call.bind(
        ProductMonitor.endpoints.upload.uploadAccount,
      ),
      {
        data: {
          global_label: '',
          global_tags: [],
          global_vendor: '',
          override: null,
          accounts: params.accounts.map((acc) => ({
            url: acc.url,
            label: acc.label,
            tags: [],
          })),
        },
      },
    );
    params.success_action();
    notification.success({
      message: `The account${
        params.accounts.length > 1 ? 's' : ''
      } has successfully been sent for recrawling. It will be updated shortly.`,
      description: `The account${
        params.accounts.length > 1 ? 's' : ''
      } has successfully been sent for recrawling. It will be updated shortly.`,
      placement: 'bottomRight',
      duration: 15,
    });
  } catch (err) {
    console.error(err);
  }
}

function* addCommentAccount(action: { [key: string]: any; objectId: string }) {
  const comments = yield select(
    (st: AppState) => st.account_view_state?.comments?.data,
  );
  yield put(
    updateAccountCommentAction(
      new FetchableData<Array<CommentModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: comments,
        error: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.accounts.comments.add.call.bind(
        ProductMonitor.endpoints.accounts.comments.add,
      ),
      {
        urlParams: { objectId: action.objectId },
        data: action.data,
      },
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    yield put(loadAccountComments(action.objectId));
  }
}
function* deleteCommentAccount(action: {
  [key: string]: any;
  objectId: string;
}) {
  const comments = yield select(
    (st: AppState) => st.account_view_state?.comments?.data,
  );
  yield put(
    updateAccountCommentAction(
      new FetchableData<Array<CommentModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: comments,
        error: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.accounts.comments.delete.call.bind(
        ProductMonitor.endpoints.accounts.comments.delete,
      ),
      {
        urlParams: { objectId: action.objectId, commentId: action.commentId },
      },
    );
    yield delay(200);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    yield put(loadAccountComments(action.objectId));
  }
}

function* loadCommentsSaga(action) {
  yield put(
    updateAccountCommentAction(
      new FetchableData<Array<CommentModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const comments: { comments: Array<CommentRaw> } = yield call(
      ProductMonitor.endpoints.accounts.comments.get.call.bind(
        ProductMonitor.endpoints.accounts.comments.get,
      ),
      {
        urlParams: { id: action.accountId },
      },
    );
    yield put(
      updateAccountCommentAction(
        new FetchableData<Array<CommentModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: comments.comments.map(CommentModel.createFromRawModel),
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateAccountCommentAction(
        new FetchableData<Array<CommentModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: (err as any).message,
        }),
      ),
    );
  }
}

function* refreshAccount(action) {
  yield put(
    updateAccount(
      new FetchableData<AccountModerationModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const account: AccountModerationRawModel = yield call(
      ProductMonitor.endpoints.accounts.getModerationAccount.call.bind(
        ProductMonitor.endpoints.accounts.getModerationAccount,
      ),
      {
        urlParams: { id: action.account_id },
      },
    );
    yield put(
      updateAccount(
        new FetchableData<AccountModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: AccountModerationModel.createFromRawModel(account),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    if (err?.response?.status === 404) {
      yield put(push('/notFound'));
    } else {
      yield put(
        updateAccount(
          new FetchableData<AccountModerationModel>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: CustomErrorFactory.create(err),
          }),
        ),
      );
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}

function* loadRelatedPosts(action) {
  const { type, selectedElementsIds } = yield select(
    (st: AppState) => st.footer_state,
  );
  const relatedPosts = yield select(
    (st: AppState) => st.account_view_state?.relatedPosts,
  );

  yield put(
    loadPostsDataAction({
      pageState: {
        poster_id: [parseInt(action.accountId.slice(3) ?? '', 10)],
        isAccountViewRelatedPosts: true,
        duplicated_group_id:
          (type === SelectedElementTypeEnum.Image && [
            ...selectedElementsIds.keys(),
          ]) ||
          null,
        ...relatedPosts.filters.queryFilterValue,
      },
      successAction: LOAD_RELATED_POSTS_ACCOUNT_SUCCESS,
    }),
  );
}

function* loadAccountResources(
  action: ReturnType<typeof loadAccountResourcesAction> & {
    skipAccountCall?: boolean;
  },
) {
  if (!action.skipAccountCall) {
    yield call(refreshAccount, { account_id: action.accountId.toString() });
  }

  yield put(
    loadAccountImagesAction({
      object_id: action.accountId,
      offset: 0,
      perpage: 10,
    }),
  );

  const relatedAccounts = yield select(
    (st: AppState) =>
      st.account_view_state?.account_moderation_data?.relatedAccounts,
  );

  yield call(loadRelatedAccounts, {
    account_id: action.accountId,
    filters: relatedAccounts.filters,
  });

  yield call(loadRelatedPosts, action);

  yield call(loadCommentsSaga, { accountId: action.accountId });
}

function* setGeoforAccount(action) {
  try {
    const myCountry = { country: action.value };

    yield call(
      ProductMonitor.endpoints.me.addGeography.call.bind(
        ProductMonitor.endpoints.me.addGeography,
      ),
      { data: myCountry, urlParams: { id: action.account_id } },
    );
  } catch (err) {
    console.error(err);
  } finally {
    yield put(action.refresh_action);
  }
}

function* delGeoforAccount(action) {
  try {
    yield call(
      ProductMonitor.endpoints.me.deleteGeography.call.bind(
        ProductMonitor.endpoints.me.deleteGeography,
      ),
      {
        urlParams: { id: action.account_id },
      },
    );
  } catch (err) {
    console.error(err);
  } finally {
    yield put(action.refresh_action);
  }
}

function* loadImagesSaga(action) {
  const { object_id, offset = 0, perpage = 10 } = action;
  const account_id = object_id.slice(3);
  const accountType = object_id.slice(0, 1);
  const accountTypeKey =
    accountType === 'p' ? 'account_poster_id' : 'account_website_id';
  try {
    yield put(
      updateAccountImagesAction({
        accountImages: new FetchableData<AssociatedImagesAccount>({
          abortController: null,
          state: FetchableDataState.LOADING,
          data: null,
          error: null,
        }),
      }),
    );
    const account_images: DuplicatedGroupResponseModel = yield call(
      ProductMonitor.endpoints.images.getImages.call.bind(
        ProductMonitor.endpoints.images.getImages,
      ),
      {
        data: {
          [accountTypeKey]: account_id,
          offset,
          sort_by: 'occurencies',
          reverse: true,
          perpage,
        } as FeedRequestParameter,
      },
    );
    yield put(
      updateAccountImagesAction({
        accountImages: new FetchableData<AssociatedImagesAccount>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            images: account_images.duplicated_groups.map(
              Image.ImageFeedModel.ImageFeedModel.createFromImageFeedRawModel,
            ),
            total: account_images.total,
          },
          error: null,
        }),
      }),
    );
  } catch (err) {
    console.error(err);
    yield put(
      updateAccountImagesAction({
        accountImages: new FetchableData<AssociatedImagesAccount>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      }),
    );
  }
}

function* performAccountModeration(action) {
  const appState: AppState = yield select((state) => state);
  const accountsBeforeModeration =
    appState.account_view_state.accounts_data.data;
  yield put(
    loadAccountsDataSuccessAction(
      new FetchableData<AccountFeedDataModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: accountsBeforeModeration,
        error: null,
      }),
    ),
  );
  try {
    const res = yield call(
      ProductMonitor.endpoints.accounts.accountsModeration.moderateAccount.call.bind(
        ProductMonitor.endpoints.accounts.accountsModeration.moderateAccount,
      ),
      { data: action.data },
    );
    if (res.message) {
      notification.info({
        message: res.message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    } else if (action.success_message) {
      notification.info({
        message: action.success_message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(
      loadAccountsDataSuccessAction(
        new FetchableData<AccountFeedDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: accountsBeforeModeration,
          error: null,
        }),
      ),
    );
    // todo: rewrite code
    if (typeof action.refresh_action === 'object') {
      yield put(action.refresh_action);
    }
    if (typeof action.refresh_action === 'function') {
      action.refresh_action();
    }
  }
}

function* moderateAccountAndUpdateInPlace(action) {
  const appState: AppState = yield select((state) => state);
  const accountBeforeModeration =
    appState.account_view_state.account_moderation_data.account.data;
  try {
    yield put(
      updateAccount(
        new FetchableData<AccountModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADING,
          data: null,
          error: null,
        }),
      ),
    );

    const res = yield call(
      ProductMonitor.endpoints.accounts.accountsModeration.moderateAndReturnAccount.call.bind(
        ProductMonitor.endpoints.accounts.accountsModeration
          .moderateAndReturnAccount,
      ),
      { data: action.data },
    );

    yield put(
      updateAccount(
        new FetchableData<AccountModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: AccountModerationModel.createFromRawModel(res),
          error: null,
        }),
      ),
    );

    if (action.success_message) {
      notification.info({
        message: action.success_message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    }
  } catch (err: any) {
    yield put(
      updateAccount(
        new FetchableData<AccountModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: accountBeforeModeration,
          error: null,
        }),
      ),
    );

    if (
      !appState.loginPage.currentUser.data?.role?.isReadOnly &&
      err.status !== 403
    ) {
      notification.error({
        message: 'Something went wrong while moderating the account',
        description: CustomErrorFactory.create(err).message,
        placement: 'bottomRight',
        duration: 15,
      });
    }

    // eslint-disable-next-line no-console
    console.error(err);
  }
}

function* loadAccountsDataSaga(action) {
  const requestParameters = Network.Account.getRequestParameter(action.payload);

  yield put(
    push({
      search: `${queryString.stringify(requestParameters)}`,
    }),
  );
  yield put(
    loadAccountsDataSuccessAction(
      new FetchableData<AccountFeedDataModel>({
        abortController: action.payload.abortController,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  try {
    const accountsData: AccountFeedResponseModel = yield call(
      ProductMonitor.endpoints.accounts.getAccounts.call.bind(
        ProductMonitor.endpoints.accounts.getAccounts,
      ),
      {
        data: requestParameters,
        signal: action.payload.abortController?.signal,
        suppressToastrOnError: true,
      },
    );

    if ((requestParameters.offset || 0) > accountsData.total) {
      const tableParams: TableParamsFilter = yield select((state: AppState) =>
        state.filters_bar.feed.currentFilters.find(
          (filter) => filter instanceof TableParamsFilter,
        ),
      );
      yield put(
        setFeedFilterAction(
          new TableParamsFilter({
            ...tableParams,
            value: tableParams.value.updatePagination(1, 0),
          }),
        ),
      );
    }

    yield put(
      loadAccountsDataSuccessAction(
        new FetchableData<AccountFeedDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            accounts: accountsData.accounts.map(
              AccountFeedModel.createFromAccountFeedRawModel,
            ),
            currency_iso: accountsData.currency_iso,
            total: accountsData.total,
          },
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      loadAccountsDataSuccessAction(
        new FetchableData<AccountFeedDataModel>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* loadRelatedAccountIds(action) {
  yield put(
    updateRelatedAccountIdsAction(
      new FetchableData<Array<string>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const allAccountIds: AccountAllRelatedIdsResponseModel = yield call(
      ProductMonitor.endpoints.accounts.getAllRelatedAccountIds.call.bind(
        ProductMonitor.endpoints.accounts.getAllRelatedAccountIds,
      ),
      {
        data: action.filters as FeedRequestParameter,
      },
    );
    yield put(
      updateRelatedAccountIdsAction(
        new FetchableData<Array<string>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: allAccountIds.accounts,
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateRelatedAccountIdsAction(
        new FetchableData<Array<string>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

function* loadRelatedAccounts(action) {
  yield put(
    updateRelatedAccounts(
      new FetchableData<Array<RelatedAccount>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const responseAccounts: RelatedAccountsResponse = yield call(
      ProductMonitor.endpoints.accounts.relatedAccounts.call.bind(
        ProductMonitor.endpoints.accounts.relatedAccounts,
      ),
      {
        urlParams: { id: action.account_id },
        params: (action.filters as TableParams).queryFilterValue,
      },
    );
    yield put(
      updateRelatedAccounts(
        new FetchableData<Array<RelatedAccount>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: (responseAccounts.accounts || []).map((account) =>
            RelatedAccount.createFromRawModel(account),
          ),
          error: null,
        }),
      ),
    );
    yield put(updateRelatedAccountsTotal(responseAccounts.total ?? 0));
  } catch (err) {
    yield put(
      updateRelatedAccounts(
        new FetchableData<Array<RelatedAccount>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

function* updateContactInformationAccount({
  contactInformation,
  accountId,
  onSuccess,
}: {
  contactInformation: Array<Contact>;
  accountId: string;
  onSuccess: () => void;
}) {
  try {
    const response = yield call(
      ProductMonitor.endpoints.accounts.setContacts.call.bind(
        ProductMonitor.endpoints.accounts.setContacts,
      ),
      {
        urlParams: {
          accountId,
        },
        data: {
          contacts: contactInformation.map((contact) =>
            contact.toSavingModel(),
          ),
        },
      },
    );
    notification.info({
      message: response.message as string,
      description: '',
      placement: 'bottomRight',
      icon: (
        <NaveeIcon.CheckGreen
          fill="var(--custom-green)"
          style={{ color: 'var(--custom-green)', fontSize: 16 }}
        />
      ),
      duration: 20,
    });
    onSuccess();
  } catch (e: any) {
    notification.error({
      message: e.message,
      placement: 'bottomRight',
      duration: 20,
    });
  } finally {
    yield put(refreshAccountAction({ account_id: accountId }));
  }
}

function* updateTakendownStatus(props: UpdateAccountTakedownStatusProps) {
  try {
    const result = yield call(
      ProductMonitor.endpoints.accounts.updateTakedownStatus.call.bind(
        ProductMonitor.endpoints.accounts.updateTakedownStatus,
      ),
      {
        data: {
          account_ids: props.account_ids,
          taken_down: props.taken_down,
        },
      },
    );
    notification.success({
      message: result.message,
      placement: 'bottomRight',
      duration: 3,
    });
    props.successAction();
  } catch (err: any) {
    if (err.status !== 403) {
      notification.error({
        message: CustomErrorFactory.create(err).message,
        placement: 'bottomRight',
        duration: 3,
      });
    }
    console.error(err);
  }
}

export default function* accountViewContainerSaga() {
  yield takeLatest(LOAD_NEXT_ACCOUNT_TO_MODERATE, loadNextAccountToModerate);
  yield takeLatest(LOAD_PREV_ACCOUNT_TO_MODERATE, loadNextAccountToModerate);
  yield takeLatest(LOAD_IMAGES_ACCOUNT, loadImagesSaga);
  yield takeLatest(LOAD_ACCOUNT_RESOURCES, loadAccountResources);
  yield takeLatest(REFRESH_ACCOUNT, refreshAccount);
  yield takeLatest(SET_GEO_ACCOUNT, setGeoforAccount);
  yield takeLatest(DEL_GEO_ACCOUNT, delGeoforAccount);
  yield takeLatest(PERFORM_ACCOUNT_MODERATION, performAccountModeration);
  yield takeLatest(
    MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE,
    moderateAccountAndUpdateInPlace,
  );
  yield takeLatest(LOAD_ACCOUNTS_DATA, loadAccountsDataSaga);
  yield takeLatest(ADD_COMMENT_ACCOUNT, addCommentAccount);
  yield takeLatest(DELETE_COMMENT_ACCOUNT, deleteCommentAccount);
  yield takeLatest(LOAD_COMMENTS_ACCOUNT, loadCommentsSaga);
  yield takeLatest(LOAD_RELATED_ACCOUNTS, loadRelatedAccounts);
  yield takeLatest(LOAD_ALL_RELATED_ACCOUNT_IDS, loadRelatedAccountIds);
  yield takeLatest(LOAD_ACCOUNT_RELATED_POSTS, loadRelatedPosts);
  yield takeLatest(
    UPDATE_CONTACT_INFORMATION_ACCOUNT,
    updateContactInformationAccount,
  );
  yield takeLatest(RECRAWL_ACCOUNT, recrawlAccounts);
  yield takeLatest(UPDATE_ACCOUNT_TAKEN_DOWN_STATUS, updateTakendownStatus);
}
