import { makeArrayUniqueByValue } from 'product-utils/src/array';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  search_keyword: Array<string>;
}

export class KeywordsCleanlinessFilterValue implements FilterValue {
  searchKeyword: Array<string>;

  constructor(params: Pick<KeywordsCleanlinessFilterValue, 'searchKeyword'>) {
    this.searchKeyword = params.searchKeyword;
  }

  addSearchKeyword(keywords: string[]) {
    return new KeywordsCleanlinessFilterValue({
      searchKeyword: makeArrayUniqueByValue(
        this.searchKeyword.concat(keywords),
      ),
    });
  }

  setSearchKeyword(keywords: string[]) {
    return new KeywordsCleanlinessFilterValue({
      searchKeyword: keywords,
    });
  }

  hasSearchKeyword(keyword: string) {
    return this.searchKeyword.some((key) => keyword === key);
  }

  removeSearchKeyword(keyword: string) {
    return new KeywordsCleanlinessFilterValue({
      searchKeyword: this.searchKeyword.filter((key) => key !== keyword),
    });
  }

  clearSearchKeyword() {
    return new KeywordsCleanlinessFilterValue({
      searchKeyword: [],
    });
  }

  static get defaultValue(): KeywordsCleanlinessFilterValue {
    return new KeywordsCleanlinessFilterValue({
      searchKeyword: new Array<string>(),
    });
  }

  static readFilterFromQuery(
    props?: readFilterFromQueryProps,
  ): KeywordsCleanlinessFilterValue {
    const urlParams = new URLSearchParams(window.location.search);

    const searchKeyword = makeArrayUniqueByValue(
      urlParams.getAll('search_keyword'),
    );

    return new KeywordsCleanlinessFilterValue({
      searchKeyword: (searchKeyword || [])
        .map((keyword: string) =>
          (props?.search_keyword || []).find((key) => key === keyword),
        )
        .filter((item) => item !== undefined) as string[],
    });
  }
}
