/*
 *
 * LoginPage reducer
 *
 */
import { produce } from 'immer';
import Cookies from 'js-cookie';

import { LoginOrganisation } from 'product-types/src/domain/organisation/LoginOrganisation';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { UserLoginModel } from 'product-types/src/domain/user/UserLoginModel';

import { UserComplete } from 'product-types/src/domain/user/UserComplete';
import {
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CLEAR_ERROR,
  LOGIN_ERROR,
  RESET_STATE,
  UPDATE_AUTHENTIFICATION,
  UPDATE_LINK_HANDLED,
  UPDATE_ORGANISATIONS,
  UPDATE_SWITCHING_ORGANISATION,
  UPDATE_TARGET_ORGANISATION,
  UPDATE_USER_INFO,
  UPDATE_USER_MODEL,
} from './constants';

export function getIsAuthenticatedFromLocalStorage() {
  return !!Cookies.get('isAuthentificated');
}

export enum LinkHandlingState {
  pendingHandling = 'pendingHandling',
  handled = 'handled',
  unknown = 'unknown',
  handling = 'handling',
  noNeedToHandle = 'noNeedToHandle',
}

export const createInitialState = () => ({
  email: '',
  password: '',
  error: '',
  organisations: FetchableData.default<LoginOrganisation[]>(),
  userInfo: FetchableData.default<UserLoginModel>(),
  currentUser: FetchableData.default<UserComplete>(),
  targetOrganisation: null as LoginOrganisation | null,
  switchingOrganisation: false,
  isAuthenticated: getIsAuthenticatedFromLocalStorage(),
  linkHandled: LinkHandlingState.unknown,
  mounted: true,
});

export type LoginPageState = ReturnType<typeof createInitialState>;

/* eslint-disable default-case, no-param-reassign, default-param-last */
const loginPageReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case CHANGE_EMAIL:
        draft.email = action.email;
        break;
      case CHANGE_PASSWORD:
        draft.password = action.password;
        break;
      case UPDATE_TARGET_ORGANISATION:
        draft.targetOrganisation = action.targetOrganisation;
        break;
      case LOGIN_ERROR:
        draft.error = action.error.message;
        break;
      case CLEAR_ERROR:
        draft.error = '';
        break;
      case UPDATE_ORGANISATIONS:
        draft.organisations = action.organisations;
        break;
      case UPDATE_USER_INFO:
        draft.userInfo = action.userInfo;
        break;
      case UPDATE_USER_MODEL:
        draft.currentUser = action.userModel;
        break;
      case UPDATE_LINK_HANDLED:
        draft.linkHandled = action.handled;
        break;
      case UPDATE_AUTHENTIFICATION:
        draft.isAuthenticated = getIsAuthenticatedFromLocalStorage();
        break;
      case UPDATE_SWITCHING_ORGANISATION:
        draft.switchingOrganisation = action.switchingOrganisation;
        break;
    }
    return draft;
  });

export default loginPageReducer;
